import React from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import logo_small from '../images/logo_white_small50X50.png'

const NavTop = () => {

   const { t, i18n } = useTranslation()

   const lngs = {
      en: { nativeName: 'English' },
      es: { nativeName: 'Española' }
   };

   const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
   };

   return (
      <>
         <Navbar fixed="top" className="background-grad-hor-reverce" collapseOnSelect expand="sm" variant="dark" >
            <Navbar.Brand><img src={logo_small} alt="Axsios Trade" />&nbsp;{t('menu.top.brand')}</Navbar.Brand>
            {/* <Badge variant="danger" pill className="mr-auto" ><FontAwesomeIcon icon={faBroadcastTower} ></FontAwesomeIcon></Badge> */}

            <Navbar.Toggle aria-controls="top-navbar-nav" />
            <Navbar.Collapse id="top-navbar-nav">
               <Nav>
                  <NavDropdown title={t('menu.top.lang')} id="basic-nav-dropdown" >
                     {Object.keys(lngs).map((lng) => (
                        <NavDropdown.Item
                           key={lng}
                           onClick={() => changeLanguage(lng)}
                           active={i18n.language === lng ? true : false}
                           className="px-1"
                        >
                           <img alt={lng} src={`images/flags/${lng}.png`} className="mr-2" />
                           {lngs[lng].nativeName}
                        </NavDropdown.Item>
                     ))}
                  </NavDropdown>
                  <Nav.Link as={NavLink} exact to={`${process.env.PUBLIC_URL}/`} href={`${process.env.PUBLIC_URL}/`} >{t('menu.top.home')}</Nav.Link>
                  <div className="border-top border-white my-1" ></div>

                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/convert`} href={`${process.env.PUBLIC_URL}/convert`} >{t('menu.top.convert')}</Nav.Link>
                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/deposit`} href={`${process.env.PUBLIC_URL}/deposit`} >{t('menu.top.deposit')}</Nav.Link>
                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/withdraw`} href={`${process.env.PUBLIC_URL}/withdraw`} >{t('menu.top.withdraw')}</Nav.Link>
                  <div className="border-top border-white my-1" ></div>

                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/yields`} href={`${process.env.PUBLIC_URL}/yields`} >{t('menu.top.yields')}</Nav.Link>
                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/liquidityprovider`} href={`${process.env.PUBLIC_URL}/liquidityprovider`} >{t('menu.top.liquidityprovider')}</Nav.Link>
                  <div className="border-top border-white my-1" ></div>

                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/invite`} href={`${process.env.PUBLIC_URL}/invite`} >{t('menu.top.invite')}</Nav.Link>
                  <div className="border-top border-white my-1" ></div>

                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/market`} href={`${process.env.PUBLIC_URL}/market`} >{t('menu.top.market')}</Nav.Link>
                  <div className="border-top border-white my-1" ></div>

                  {/*
                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/crypto/BTC`} href={`${process.env.PUBLIC_URL}/crypto/BTC`} >BTC</Nav.Link>
                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/crypto/ETH`} href={`${process.env.PUBLIC_URL}/crypto/ETH`} >ETH</Nav.Link>
                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/crypto/USDT`} href={`${process.env.PUBLIC_URL}/crypto/USDT`} >USDT</Nav.Link>
                  <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/crypto/DOGE`} href={`${process.env.PUBLIC_URL}/crypto/DOGE`} >DOGE</Nav.Link>
                  */}
                  {/* <div className="border-top border-white my-1" ></div> */}
                  {/* <Nav.Link as={NavLink} to={`${process.env.PUBLIC_URL}/logout`} href={`${process.env.PUBLIC_URL}/logout`} >Logout</Nav.Link> */}
                  <Nav.Link
                     variant="danger"
                     as={NavLink}
                     className="bg-transparent btn btn-danger"
                     to={`${process.env.PUBLIC_URL}/logout`}
                     href={`${process.env.PUBLIC_URL}/logout`}
                  >{t('menu.top.signout')}</Nav.Link>
               </Nav>
               <Nav className="ml-auto">
               </Nav>
            </Navbar.Collapse>
         </Navbar>
      </>
   )
}

export default NavTop
