import { combineReducers } from "redux";
import { appReducer } from "./reducers/app";
import { authReducer } from "./reducers/auth";
import { cryptoReducer } from "./reducers/crypto";
import { depositReducer } from "./reducers/pay";
import { ratesReducer } from "./reducers/rates";
import { recoverReducer } from "./reducers/recover";
import { walletsReducer } from "./reducers/wallet";
import { savingsReducer } from "./reducers/savings";

export const rootReducer = combineReducers({
   app: appReducer,
   auth: authReducer,
   recover: recoverReducer,
   rates: ratesReducer,
   crypto: cryptoReducer,
   wallets: walletsReducer,
   deposit: depositReducer,
   savings: savingsReducer
})