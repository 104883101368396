import {
   CRYPTO_INFO_LOAD_FAILED,
   CRYPTO_INFO_LOAD_START,
   CRYPTO_INFO_LOAD_SUCCESS
} from "../types/crypto"

const initialState = {
   loading: false,
   message: {
      info: null
   },
   info: {}
}

export const cryptoReducer = (state = initialState, action) => {
   switch (action.type) {

      case CRYPTO_INFO_LOAD_START:
         return { ...state, loading: true, message: { ...state.message, info: null } }

      case CRYPTO_INFO_LOAD_SUCCESS:
         return { ...state, loading: false, info: { ...state.info, ...reduceInfo(action.payload) } }

      case CRYPTO_INFO_LOAD_FAILED:
         return { ...state, loading: false, message: { ...state.message, info: action.payload } }

      default: return state
   }
}

const reduceInfo = (info) => {
   for (const key_crypto in info) {
      if (Object.hasOwnProperty.call(info, key_crypto)) {
      }
   }

   return info
}