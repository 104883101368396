import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ContentBlock from '../components/ContentBlock';
import logo from '../images/logo_white320x320.png';

const PageWelcome = () => {

   const { t } = useTranslation()

   const { authorized } = useSelector((state) => state.auth)
   let history = useHistory();
   let location = useLocation();
   let { from } = location.state || { from: { pathname: "/" } };

   authorized && setTimeout(() => {
      history.replace(from)
   }, 0);

   return (
      <Container className="px-1 background-grad-ver h-100">
         <ContentBlock>
            <Row>
               <Col sm={12} className="text-left pt-3">
                  <span>&nbsp;</span>
               </Col>
            </Row>
            <Row>
               <Col sm={12} className="text-center"><img style={{ maxHeight: '200px' }} src={logo} alt="logo" /></Col>
            </Row>
         </ContentBlock>

         <ContentBlock>
            <Row>
               <Col sm={12} className="text-center">
                  <h4 className="text-center text-white font-weight-bolder mb-2">{t('page.welcome.title')}</h4>
               </Col>
            </Row>
            <Row className="mt-4">
               <Col sm={12} md={6} xl={4} className="mx-auto text-center">
                  <Button
                     variant="light"
                     size="lg"
                     className="w-75 text-custom-blue font-weight-bold font-size-large button-rounded-1"
                     as={Link}
                     to={`${process.env.PUBLIC_URL}/login`}>
                     {t('page.welcome.button.login')}
                  </Button>
               </Col>
            </Row>
            <Row className="mt-4">
               <Col sm={12} md={6} xl={4} className="mx-auto text-center">
                  <Button
                     variant="light"
                     size="lg"
                     className="w-75 text-custom-blue font-weight-bold font-size-large button-rounded-1"
                     as={Link}
                     to={`${process.env.PUBLIC_URL}/signup`}>
                     {t('page.welcome.button.signup')}
                  </Button>
               </Col>
            </Row>

            <Row className="mt-4">
               <Col sm={12} md={6} xl={4} className="mx-auto text-center">
                  <Button
                     variant="light"
                     size="lg"
                     className="w-75 text-custom-blue font-weight-bold font-size-large button-rounded-1"
                     as={Link}
                     to={`${process.env.PUBLIC_URL}/invite`}>
                     {t('page.welcome.button.invite')}
                  </Button>
               </Col>
            </Row>
         </ContentBlock>
      </Container>
   )
}

export default PageWelcome
