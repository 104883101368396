import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import ContentBlock from '../../components/ContentBlock'
import { logout } from '../../redux/actions/auth'
import { walletsClearAll } from '../../redux/actions/wallet'

const PageLogout = ({ auth, logout, walletsClearAll }) => {

   const { t } = useTranslation()

   let history = useHistory();
   let location = useLocation();

   const cancelLogout = () => {
      let { from } = location.state || { from: { pathname: "/" } };
      history.replace(from)
   }

   const doLogout = () => {
      walletsClearAll()
      logout()
   }


   return (
      <Container className="px-1 py-4 background-grad-ver h-100">
         <ContentBlock className="bg-white border border-danger">
            <h4 className="text-center text-danger font-weight-bold mb-4">
               {t('page.logout.title.row1')}
               <br />
               {t('page.logout.title.row2')}
            </h4>
            <Row>
               <Col xs={6}><Button size="lg" variant="outline-dark" className="w-100" onClick={cancelLogout}>{t('page.logout.button.cancel')}</Button></Col>
               <Col xs={6}><Button size="lg" variant="danger" className="w-100" onClick={doLogout}>{t('page.logout.button.logout')}</Button></Col>
            </Row>
         </ContentBlock>

      </Container>
   )
}

const mapStateToProps = state => {
   return {
      auth: state.auth
   }
}

const mapDispatchToProps = {
   logout, walletsClearAll
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLogout)
