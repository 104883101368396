import {
   SAVINGS_CLEAR_ALL,
   SAVINGS_DEPOSIT_FAILED,
   SAVINGS_DEPOSIT_REQUESTED,
   SAVINGS_DEPOSIT_START,
   SAVINGS_DEPOSIT_SUCCESS,
   SAVINGS_LOAD_FAILED,
   SAVINGS_LOAD_START,
   SAVINGS_LOAD_SUCCESS,
   SAVINGS_REQUESTED,
   SAVINGS_WITHDRAW_CLEAR_MSG,
   SAVINGS_WITHDRAW_FAILED,
   SAVINGS_WITHDRAW_REQUESTED,
   SAVINGS_WITHDRAW_START,
   SAVINGS_WITHDRAW_SUCCESS
} from "../types/savings"

export const requestSavings = () => {
   return {
      type: SAVINGS_REQUESTED
   }
}

export const savingsLoadStart = () => {
   return {
      type: SAVINGS_LOAD_START
   }
}

export const savingsLoadSuccess = payload => {
   return {
      type: SAVINGS_LOAD_SUCCESS,
      payload
   }
}

export const savingsLoadFaild = () => {
   return {
      type: SAVINGS_LOAD_FAILED
   }
}


/* WITHDRAW */
export const requestSavingWithdraw = payload => {
   return {
      type: SAVINGS_WITHDRAW_REQUESTED,
      payload
   }
}

export const savingWithdrawStart = () => {
   return {
      type: SAVINGS_WITHDRAW_START
   }
}

export const savingWithdrawSuccess = payload => {
   return {
      type: SAVINGS_WITHDRAW_SUCCESS,
      payload
   }
}

export const savingWithdrawFaild = payload => {
   return {
      type: SAVINGS_WITHDRAW_FAILED,
      payload
   }
}

export const savingWithdrawClearMSG = () => {
   return {
      type: SAVINGS_WITHDRAW_CLEAR_MSG
   }
}

/* DEPOSIT */
export const requestSavingDeposit = payload => {
   return {
      type: SAVINGS_DEPOSIT_REQUESTED,
      payload
   }
}

export const savingDepositStart = () => {
   return {
      type: SAVINGS_DEPOSIT_START
   }
}

export const savingDepositSuccess = payload => {
   return {
      type: SAVINGS_DEPOSIT_SUCCESS,
      payload
   }
}

export const savingDepositFaild = payload => {
   return {
      type: SAVINGS_DEPOSIT_FAILED,
      payload
   }
}

export const savingDepositClearMSG = () => {
   return {
      type: SAVINGS_WITHDRAW_CLEAR_MSG
   }
}


export const savingsClearAll = () => {
   return {
      type: SAVINGS_CLEAR_ALL
   }
}
