import {
   CRYPTO_INFO_LOAD_FAILED,
   CRYPTO_INFO_LOAD_START,
   CRYPTO_INFO_LOAD_SUCCESS,
   CRYPTO_INFO_REQUESTED
} from "../types/crypto"

export const requestInfo = () => {
   return {
      type: CRYPTO_INFO_REQUESTED
   }
}

export const infoLoadStart = () => {
   return {
      type: CRYPTO_INFO_LOAD_START
   }
}

export const infoLoadSuccess = payload => {
   return {
      type: CRYPTO_INFO_LOAD_SUCCESS,
      payload
   }
}

export const infoLoadFaild = payload => {
   return {
      type: CRYPTO_INFO_LOAD_FAILED,
      payload
   }
}
