import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import ContentBlock from '../components/ContentBlock';
import WalletQrCode from '../components/WalletQrCode';
import UniversalFullView from '../components/wallet/UniversalFullView';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import CryptoInfoBlock from '../components/crypto/CryptoInfoBlock';

const PageCrypto = ({ wallets, rates, crypto }) => {
   let { currency } = useParams();
   const logo = <img alt={currency} src={`/icons/${currency.toLowerCase()}.svg`} />
   return (
      <Container className="px-1">
         <Row className="mx-1 my-3">
            <Col sm={12} className="text-left text-custom-blue font-weight-bold">
               <Link to={`${process.env.PUBLIC_URL}/`} className="text-custom-blue">
                  <FontAwesomeIcon icon={faChevronLeft} />&nbsp;
                  <span>back</span>
               </Link>
               <span className="ml-5">List / Chart</span>
            </Col>
         </Row>
         <ContentBlock className="border border-dark mx-4 my-2">
            <UniversalFullView
               rates={rates.data[currency]}
               wallet={wallets[currency]}
               code={currency}
               title={rates.names[currency]}
               logo={logo}
            />
         </ContentBlock>

         {wallets[currency]?.settings?.is_buyable &&
            <Row className="mx-2 my-3">
               <Col sm={12} className="text-center" >
                  <Button
                     as={Link}
                     size="lg"
                     to={`${process.env.PUBLIC_URL}/deposit`}
                     className="background-grad-hor-reverce w-75">
                     Trade / Buy {currency}
                  </Button>
               </Col>
            </Row>
         }

         {wallets[currency].address?.number !== "" &&
            <ContentBlock>
               <WalletQrCode address={wallets[currency].address.number} />
            </ContentBlock>
         }

         {crypto.info[currency] &&
            <ContentBlock>
               <CryptoInfoBlock info={crypto.info[currency]} />
            </ContentBlock>
         }
      </Container>
   )
}

const mapStateToProps = state => {
   return {
      wallets: state.wallets,
      rates: state.rates,
      crypto: state.crypto
   }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PageCrypto)
