import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UniversalCompactView = ({ rates, wallet, title, code, logo, ...rest }) => {

   const { available /*, unconfirmed, locked */ } = wallet.value;

   const usdAmount = rates ? available * rates.USD.price : 0
   /*
      const mapTokens = () => {
         const tokens = [];
   
         for (const sign in wallet.tokens) {
            if (Object.hasOwnProperty.call(wallet.tokens, sign)) {
               const token = wallet.tokens[sign]
   
               const element =
                  <Row>
                     <Col className='text-left'>
                        <img className='mr-2' height="20" src={'/icons/tokens/' + token.token_sign.toLowerCase() + '.png'} alt='' />
                        {token.token_sign}<sub className='muted'>({token.token_type})</sub>
                     </Col>
                     <Col className='text-right'>{token.available.toFixed(0)}&nbsp;(${(token.available * token.rate).toFixed(2)})</Col>
                  </Row>
   
               tokens.push(element)
            }
         }
   
         return tokens
      }
   */

   return (
      <>
         <Row {...rest}>
            <Col className="my-auto" xs={2} md={1}>{logo}</Col>
            <Col xs={5} md={6}>
               <Row className="h-30">
                  <Col className="my-auto">{title}</Col>
               </Row>
               <Row className="h-30">
                  <Col className="my-auto">{available.toFixed(wallet.settings.is_token ? 0 : 8)}</Col>
               </Row>
               <Row className="h-30">
                  <Col className="my-auto">$ {(usdAmount).toFixed(2)}</Col>
               </Row>
            </Col>
            <Col xs={5} md={5} className="ml-auto p-0">
               <Row className="h-50">
                  <Col className="my-auto text-center">{code} / $</Col>
               </Row>
               <Row className="h-50">
                  <Col className="my-auto text-center">
                     {rates !== undefined
                        ? rates.USD.percent_change_1h > 0
                           ? <span className="text-success mx-1"><FontAwesomeIcon icon={faSortUp} /></span>
                           : <span className="text-danger mx-1"><FontAwesomeIcon icon={faSortDown} /></span>
                        : '------'}
                     {rates !== undefined ? rates.USD.price.toFixed(4) : '------'}
                  </Col>
               </Row>
            </Col>
         </Row>

         {/* {wallet.tokens &&
            <>
               <Row>
                  <Col>+ {Object.keys(wallet.tokens).length} Token(s)</Col>
               </Row>
               {mapTokens()}
            </>
         } */}
      </>
   )
}

export default UniversalCompactView
