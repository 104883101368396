import { call, put, select, takeEvery } from 'redux-saga/effects'
import { Api } from '../../Api'
import { appHideLoader, appShowLoader } from '../actions/app';
import { authMsgSet, checkLoginFaild, checkLoginStart, checkLoginSuccess, loginFaild, loginStart, loginSuccess, registerFaild, registerStart, registerSuccess } from '../actions/auth';
import { requestWallets } from '../actions/wallet';
import { LOGIN_CHECK_REQUESTED, LOGIN_REQUESTED, REGISTER_REQUESTED } from '../types/auth';

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* fetchLogin(action) {
   yield put(loginStart());
   yield put(appShowLoader())

   try {
      const result = yield call(Api.fetchLogin, action.payload);
      yield put(loginSuccess(result.data));
      yield put(authMsgSet(result.errors || [result.message || 'Login successfull']));
      //yield put(requestWallets())
   } catch (e) {
      yield put(loginFaild());
      yield put(authMsgSet(e.errors || [e.message || 'Something went wrong']));
   }

   yield put(appHideLoader())
}

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* fetchRegister(action) {
   yield put(registerStart());
   yield put(appShowLoader())

   try {
      const result = yield call(Api.fetchRegister, action.payload);
      yield put(registerSuccess(result.data));
      yield put(authMsgSet(result.errors || [result.message || 'Register successfull']));
      //yield put(requestWallets())
   } catch (e) {
      yield put(registerFaild());
      yield put(authMsgSet(e.errors || [e.message || 'Something went wrong']));
   }

   yield put(appHideLoader())
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
/*
export function* loginSaga() {
   yield takeLatest(LOGIN_REQUESTED, fetchLogin);
 }
 */

// worker Saga: will be fired on LOGIN_CHECK_REQUESTED actions
function* checkLogin(action) {
   yield put(checkLoginStart());
   yield put(appShowLoader())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.checkLogin, token);
      //console.log(result)
      yield put(checkLoginSuccess(result.data));
      yield put(authMsgSet(result.errors || [result.message || 'Login successfull']));
      yield put(requestWallets())
   } catch (e) {
      yield put(checkLoginFaild());
      yield put(authMsgSet(e.errors || [e.message || 'Something went wrong']));
   }

   yield put(appHideLoader())
}

/*
  Starts checkLogin on each dispatched `LOGIN_CHECK_REQUESTED` action.
  Starts fetchLogin on each dispatched `LOGIN_REQUESTED` action.
  Starts fetchRegister on each dispatched `LOGIN_REQUESTED` action.
*/
export function* authSagas() {
   yield takeEvery(LOGIN_CHECK_REQUESTED, checkLogin);
   yield takeEvery(LOGIN_REQUESTED, fetchLogin);
   yield takeEvery(REGISTER_REQUESTED, fetchRegister);
}
