export const SAVINGS_REQUESTED = "SAVINGS/SAVINGS_REQUESTED"
export const SAVINGS_LOAD_START = "SAVINGS/SAVINGS_LOAD_START"
export const SAVINGS_LOAD_SUCCESS = "SAVINGS/SAVINGS_LOAD_SUCCESS"
export const SAVINGS_LOAD_FAILED = "SAVINGS/SAVINGS_LOAD_FAILED"

export const SAVINGS_WITHDRAW_REQUESTED = "SAVINGS/WITHDRAW_REQUESTED"
export const SAVINGS_WITHDRAW_START = "SAVINGS/WITHDRAW_START"
export const SAVINGS_WITHDRAW_SUCCESS = "SAVINGS/WITHDRAW_SUCCESS"
export const SAVINGS_WITHDRAW_FAILED = "SAVINGS/WITHDRAW_FAILED"
export const SAVINGS_WITHDRAW_CLEAR_MSG = "SAVINGS/WITHDRAW_CLEAR_MSG"

export const SAVINGS_DEPOSIT_REQUESTED = "SAVINGS/DEPOSIT_REQUESTED"
export const SAVINGS_DEPOSIT_START = "SAVINGS/DEPOSIT_START"
export const SAVINGS_DEPOSIT_SUCCESS = "SAVINGS/DEPOSIT_SUCCESS"
export const SAVINGS_DEPOSIT_FAILED = "SAVINGS/DEPOSIT_FAILED"
export const SAVINGS_DEPOSIT_CLEAR_MSG = "SAVINGS/DEPOSIT_CLEAR_MSG"


export const SAVINGS_CLEAR_ALL = "SAVINGS/CLEAR_ALL"

