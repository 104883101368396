import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from 'styled-components'

const Styles = styled.div`
   font-size : 1rem
`

const TotalAmount = ({ rates, wallets }) => {

   const { t } = useTranslation()

   const total = () => {
      let totalUSD = 0

      for (const key in rates.names) {
         if (Object.hasOwnProperty.call(rates.data, key)) {
            const price = rates.data[key]?.USD?.price ?? 0
            const amount = wallets[key]?.value?.available ?? 0
            totalUSD += amount * price
         }
      }

      return totalUSD
   }

   return (
      <Styles>
         <Row>
            <Col xs={7} className="text-custom-blue font-weight-bold">{t('common.mybalance')} : </Col>
            <Col xs={5} className="text-left text-custom-green font-weight-bold">$ {total().toFixed(2)}</Col>
         </Row>
      </Styles>
   )
}

const mapStateToProps = (state) => ({
   rates: state.rates,
   wallets: state.wallets
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TotalAmount)
