import React from 'react'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ContentBlock from '../components/ContentBlock'
import logo from '../images/logo_white320x320.png'

const PageWithdrawTerms = () => {


   const { t } = useTranslation()

   return (
      <Container className="px-1 background-grad-ver h-100">
         <ContentBlock>
            <Row>
               <Col sm={12} className="text-left pt-3">
                  <Link to={`${process.env.PUBLIC_URL}/withdraw`} className="text-white font-weight-bold">
                     <FontAwesomeIcon icon={faChevronLeft} />&nbsp;
                     <span>{t('common.back')}</span>
                  </Link>
               </Col>
            </Row>
            <Row>
               <Col sm={12} className="text-center"><img style={{ maxHeight: '200px' }} src={logo} alt="logo" /></Col>
            </Row>
         </ContentBlock>

         <ContentBlock>
            <Row>
               <Col sm={12} className="text-center">
                  <h4 className="text-center text-warning">{t('page.withdrawterms.title1')}</h4>
                  <hr className="border-warning" />
               </Col>
            </Row>
            <Row>
               <Col sm={12} className="text-center">
                  <h5 className="text-white">{t('page.withdrawterms.amount100')}</h5>
                  <h5 className="text-white">{t('page.withdrawterms.amount75')}</h5>
                  <h5 className="text-white">{t('page.withdrawterms.amount50')}</h5>
                  <h5 className="text-white">{t('page.withdrawterms.amount25')}</h5>
               </Col>
            </Row>
            <Row>
               <Col sm={12} className="text-center">
                  <hr className="border-warning" />
                  <p className="text-center text-warning">{t('page.withdrawterms.text1')}</p>
                  <hr className="border-warning" />
               </Col>
            </Row>
         </ContentBlock>
      </Container>
   )
}

export default PageWithdrawTerms