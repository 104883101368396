import React, { useEffect, useRef, useState } from 'react'
import { Modal, Alert, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import ContentBlock from '../../components/ContentBlock'
import MapMessages from '../../components/MapMessages'
import TotalAmount from '../../components/wallet/TotalAmount'
import UniversalCompactView from '../../components/wallet/UniversalCompactView'
import { requestSavings, requestSavingDeposit, requestSavingWithdraw } from '../../redux/actions/savings'
import { useCallback } from 'react'

const Styles = styled.div`
   *{
      border-color: #1461af60 !important;
   }
`

const PageLiquidityProvider = ({ rates, wallets, savings, requestSavings, requestSavingDeposit, requestSavingWithdraw }) => {


   let history = useHistory()
   let location = useLocation()

   const [path, setPath] = useState(location.pathname)

   useEffect(() => {
      history.replace({ pathname: path })
   }, [history, path])

   const { t } = useTranslation()

   const selectOptionRef = useRef(null);

   const initialState = {
      save_crypto: '',
      save_amount: 0,
      save_option: 0
   }

   const [form, setform] = useState(initialState)

   const [withdrawID, setWithdrawID] = useState(0)
   const [show, setShow] = useState(false);

   const handleClose = () => {
      setWithdrawID(0)
      setShow(false)
   }

   const setMaxValue = useCallback(() => {
      console.log(form.save_crypto, wallets[form.save_crypto]?.value?.available)
      setform({ ...form, save_amount: wallets[form.save_crypto]?.value?.available ?? 0 })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [form.save_crypto, wallets])


   const selectSavingOption = useCallback(() => {

      let selectedOptionId = 0

      const optionsCryptoCurrent = savings.options.LPR?.filter(el => el.crypto_code === form.save_crypto) ?? []
      //console.log(optionsCryptoCurrent)

      optionsCryptoCurrent.forEach(opt => {
         if (
            (opt.min_amount != null && opt.max_amount != null && opt.min_amount <= form.save_amount && opt.max_amount >= form.save_amount) ||
            (opt.min_amount == null && opt.max_amount != null && form.save_amount > 0 && opt.max_amount >= form.save_amount) ||
            (opt.min_amount != null && opt.max_amount == null && opt.min_amount <= form.save_amount)
         ) {
            selectedOptionId = opt.ID
         }
      })

      if (selectedOptionId === 0) selectedOptionId = optionsCryptoCurrent.at(0)?.ID ?? 0

      selectOptionRef.current.value = selectedOptionId
      setform(prev => ({ ...prev, ...{ save_option: selectedOptionId } }))

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [form.save_amount, form.save_crypto, form.save_option, savings.options.LPR])

   const assetsWallets = () => {

      let assetsObj = Object.keys(wallets)
         .filter(curr => wallets[curr].value?.available > 0)
         .sort((b, a) => {
            return wallets[a].value.available * rates.data[a]?.USD?.price - wallets[b].value.available * rates.data[b]?.USD?.price
         })
         .reduce((acc, key) => {
            acc[key] = wallets[key]
            return acc
         }, {})

      //console.log(assetsObj)
      return assetsObj
   }

   const mapSavingOptions = (type_code) => {
      const wArr = []

      const optionsArr = savings.options[type_code] ?? []

      optionsArr.forEach(opt => {
         if (opt.crypto_code === form.save_crypto) {
            const element = (<option key={`${opt.ID}`} value={opt.ID}>{opt.name}</option>)

            wArr.push(element)
         }
      });

      return wArr
   }

   const mapCurrencyOptions = () => {
      const wArr = []

      const savingCurrencies = savings.options?.LPR?.map(el => el.crypto_code).filter((element, index) => savings.options.LPR) ?? []

      const walletsObj = assetsWallets()

      let selected = form.save_crypto

      for (const curr in walletsObj) {
         if (Object.hasOwnProperty.call(wallets, curr)) {

            if (savingCurrencies.indexOf(curr) === -1)
               continue

            if (selected === '') selected = curr

            const element = (<option key={`${curr}`} value={curr}>{curr}</option>)

            wArr.push(element)
         }
      }

      if (selected !== '' && form.save_crypto !== selected)
         setform({ ...form, save_crypto: selected })

      return wArr
   }

   const mapWallets = () => {
      const wArr = []

      const walletsObj = assetsWallets()

      for (const curr in walletsObj) {
         if (Object.hasOwnProperty.call(rates.data, curr)) {

            if (wallets[curr].value.available === 0)
               continue

            const logo = <img alt={curr} src={`icons/${curr.toLowerCase()}.svg`} />
            const element = (
               <ContentBlock key={curr} className="border border-dark m-2">
                  <UniversalCompactView
                     rates={rates.data[curr]}
                     wallet={wallets[curr]}
                     code={curr}
                     title={rates.names[curr]}
                     logo={logo}
                     onClick={() => setPath(`/crypto/${curr.toUpperCase()}`)}
                  />
               </ContentBlock>
            )

            wArr.push(element)
         }
      }

      return wArr
   }

   const mapSavings = () => {
      const wArr = []

      if (Array.isArray(savings.user.LPR)) {
         savings.user?.LPR?.forEach(sav => {
            const element = (
               <ContentBlock className="border border-1 m-2">
                  <Row key={sav.ID}>
                     <Col>{sav.crypto_code ?? ''}<br />{sav.amount?.toFixed(3) ?? ''}</Col>
                     <Col className='text-center'>{sav.percent_year ?? ''}%</Col>
                     <Col className='text-center'>
                        {sav.last_payed ? ("0" + (sav.last_payed.getMonth() + 1)).slice(-2) + "-" + ("0" + (sav.last_payed.getDate())).slice(-2) : "--"}
                        <br />
                        {sav.last_payed ? sav.last_payed.getFullYear() : ""}
                     </Col>
                     <Col className='text-right'>
                        <Button
                           size='lg'
                           variant='danger'
                           onClick={() => setWithdrawID(sav.ID)}
                        ><FontAwesomeIcon icon={faLockOpen} /></Button>
                     </Col>
                  </Row>
               </ContentBlock>
            )
            wArr.push(element)
         })
      }

      return wArr
   }

   const changeInputHandler = e => {
      const { type, checked, name, value } = e.target;
      setform(prev => ({
         ...prev, ...{
            [name]: type === 'checkbox' ? checked : value
         }
      }))
   }

   const submitHandler = e => {
      e.preventDefault()
      requestSavingDeposit(form)
   }

   const withdrawHandler = e => {
      e.preventDefault()
      console.log("request withdraw: " + withdrawID)
      requestSavingWithdraw({ deposit_id: withdrawID })
      setWithdrawID(0);
   }

   useEffect(() => {
      requestSavings()
   }, [requestSavings])

   useEffect(() => {
      if (wallets[form.save_crypto]?.value ?? false) {
         if (form.save_amount > wallets[form.save_crypto].value.available) {
            setform({ ...form, save_amount: wallets[form.save_crypto].value.available })
         }
      }
   }, [form, wallets])

   useEffect(() => {
      setMaxValue()

   }, [form.save_crypto, wallets, savings, setMaxValue])

   useEffect(() => {
      selectSavingOption()
   }, [form.save_amount, form.save_crypto, savings, selectSavingOption])

   useEffect(() => {
      if (withdrawID !== 0) setShow(true)
      else setShow(false)
   }, [withdrawID])

   return (
      <>
         <Container className="px-1 text-custom-blue">
            <ContentBlock>
               <TotalAmount />
            </ContentBlock>

            <Styles>
               <ContentBlock className="border border-1 m-2">
                  <Form onSubmit={submitHandler}>
                     <Form.Row>
                        <Form.Group as={Col} xs={4}>
                           <Form.Label>{t('form.savings.label.from')} :</Form.Label>
                           <Form.Control
                              as="select"
                              size="lg"
                              name="save_crypto"
                              value={form.save_crypto}
                              onChange={changeInputHandler}
                           >
                              {mapCurrencyOptions()}
                           </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} xs={8}>
                           <Form.Label>{t('form.savings.label.amount')}</Form.Label>
                           <InputGroup>
                              <Form.Control
                                 size="lg"
                                 name="save_amount"
                                 type="number"
                                 step="any"
                                 inputMode="decimal"
                                 value={form.save_amount}
                                 onChange={changeInputHandler}
                              />
                              <InputGroup.Append>
                                 <Button className="background-grad-hor" variant="secondary" onClick={setMaxValue}>
                                    {t('form.savings.button.max')}
                                 </Button>
                              </InputGroup.Append>
                           </InputGroup>
                        </Form.Group>
                     </Form.Row>

                     <Form.Row>
                        <Form.Group as={Col} xs={12}>
                           <Form.Label>{t('form.savings.label.options_lpr')} :</Form.Label>
                           <Form.Control
                              as="select"
                              size="lg"
                              name="save_option"
                              value={form.save_option}
                              onChange={changeInputHandler}
                              style={{ fontSize: '1rem' }}
                              ref={selectOptionRef}
                           >
                              {mapSavingOptions("LPR")}
                           </Form.Control>
                        </Form.Group>
                     </Form.Row>

                     <Row>
                        <Col>
                           <Button type="submit" className="w-100 background-grad-hor-reverce" variant="dark" size="lg">
                              {t('form.savings.button.submit')}
                           </Button>
                        </Col>
                     </Row>
                     {savings.deposit.message &&
                        <Row className="mt-3">
                           <Col>
                              <Alert
                                 className="font-weight-bold text-center"
                                 size="xl"
                                 variant={savings.deposit.success ? 'success' : 'danger'}
                              >
                                 {MapMessages(savings.deposit.message)}
                              </Alert>
                           </Col>
                        </Row>
                     }
                     {savings.withdraw.message &&
                        <Row className="mt-3">
                           <Col>
                              <Alert
                                 className="font-weight-bold text-center"
                                 size="xl"
                                 variant={savings.withdraw.success ? 'success' : 'danger'}
                              >
                                 {MapMessages(savings.withdraw.message)}
                              </Alert>
                           </Col>
                        </Row>
                     }
                  </Form>
               </ContentBlock>

               {savings.user?.LPR?.length && mapSavings()}
            </Styles>


            <h4 className="text-center text-custom-blue my-3">{t('page.convert.title1')}</h4>

            {mapWallets()}

         </Container>

         <Modal show={show} onHide={handleClose} animation={false} centered>
            <Modal.Header closeButton>
               <Modal.Title>Withdraw deposit</Modal.Title>
            </Modal.Header>
            <Modal.Body>You are going to close deposit and withdraw all the amount.<br />Are you shure?</Modal.Body>
            <Modal.Footer>
               <Button className='col' variant="secondary" onClick={handleClose}>
                  Cancel
               </Button>
               <Button className="col background-grad-hor-reverce" variant="dark" onClick={withdrawHandler}>
                  Withdraw
               </Button>
            </Modal.Footer>
         </Modal>
      </>
   )
}


const mapStateToProps = state => {
   return {
      rates: state.rates,
      wallets: state.wallets,
      savings: state.savings
   }
}

const mapDispatchToProps = {
   requestSavings,
   requestSavingDeposit,
   requestSavingWithdraw
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLiquidityProvider)