import { useEffect } from 'react';

const useScript = ({ url, body, target }) => {
   useEffect(() => {

      const container = document.getElementById(target) ?? document.body
      const script = document.createElement('script')

      script.src = url
      script.text = body
      script.async = true

      container.appendChild(script)

      return () => {
         try {
            container.removeChild(script)
         } catch (ex) {
            //console.log(ex)
         }
      }
   }, [body, target, url])
}

export default useScript