import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ContentBlock from '../components/ContentBlock'
import MapMessages from '../components/MapMessages'
import TotalAmount from '../components/wallet/TotalAmount'
import UniversalCompactView from '../components/wallet/UniversalCompactView'
import { requestConvertion } from '../redux/actions/wallet'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Styles = styled.div`
   *{
      border-color: #1461af60 !important;
   }
`

const PageConvert = ({ rates, wallets, requestConvertion }) => {

   const { t } = useTranslation()

   const initialState = {
      convert_from: 'BTC',
      convert_amount: wallets.BTC?.value?.available ?? 0,
      convert_to: 'ETH'
   }

   const [form, setform] = useState(initialState)

   const [converted, setConverted] = useState(0)

   useEffect(() => {
      if (wallets[form.convert_from]?.value ?? false) {
         if (form.convert_amount > wallets[form.convert_from].value.available) {
            setform({ ...form, convert_amount: wallets[form.convert_from].value.available })
         }

         let usd_from = (form.convert_amount * rates.data[form.convert_from].USD.price)
         let amount = usd_from / rates.data[form.convert_to].USD.price
         setConverted(amount.toFixed(8))
      }
   }, [converted, form, form.convert_amount, form.convert_from, form.convert_to, rates, wallets])

   const setMaxValue = () => {
      //console.log(wallets[form.convert_from])
      setform({ ...form, convert_amount: wallets[form.convert_from].value.available })
   }

   const mapOptions = () => {
      const wArr = []

      for (const curr in rates.data) {
         if (Object.hasOwnProperty.call(wallets, curr)) {
            if (undefined !== wallets[curr].settings.is_converted && !wallets[curr].settings.is_converted) continue

            const element = (<option key={`${curr}`} value={curr}>{curr}</option>)

            wArr.push(element)
         }
      }

      return wArr
   }

   let history = useHistory()
   let location = useLocation()

   const [path, setPath] = useState(location.pathname)

   useEffect(() => {
      history.replace({ pathname: path })
   }, [history, path])

   const assetsWallets = () => {

      let assetsObj = Object.keys(wallets)
         .filter(curr => wallets[curr].value?.available > 0)
         .sort((b, a) => {
            return wallets[a].value.available * rates.data[a]?.USD?.price - wallets[b].value.available * rates.data[b]?.USD?.price
         })
         .reduce((acc, key) => {
            acc[key] = wallets[key]
            return acc
         }, {})

      //console.log(assetsObj)
      return assetsObj
   }

   const mapWallets = () => {
      const wArr = []

      const walletsObj = assetsWallets()

      for (const curr in walletsObj) {
         if (Object.hasOwnProperty.call(rates.data, curr)) {

            if (wallets[curr].value.available === 0)
               continue

            const logo = <img alt={curr} src={`icons/${curr.toLowerCase()}.svg`} />
            const element = (
               <ContentBlock key={curr} className="border border-dark m-2">
                  <UniversalCompactView
                     rates={rates.data[curr]}
                     wallet={wallets[curr]}
                     code={curr}
                     title={rates.names[curr]}
                     logo={logo}
                     onClick={() => setPath(`/crypto/${curr.toUpperCase()}`)}
                  />
               </ContentBlock>
            )

            wArr.push(element)
         }
      }

      return wArr
   }

   const changeInputHandler = e => {
      const { type, checked, name, value } = e.target;
      setform(prev => ({
         ...prev, ...{
            [name]: type === 'checkbox' ? checked : value
         }
      }))
   }

   const submitHandler = e => {
      e.preventDefault()
      requestConvertion(form)
   }

   return (
      <Container className="px-1 text-custom-blue">
         <ContentBlock>
            <TotalAmount />
         </ContentBlock>

         <Styles>
            <ContentBlock className="border border-1 m-2">
               <Form onSubmit={submitHandler}>
                  <Form.Row>
                     <Form.Group as={Col} xs={4}>
                        <Form.Label>{t('form.convert.label.from')} :</Form.Label>
                        <Form.Control
                           as="select"
                           size="lg"
                           name="convert_from"
                           value={form.convert_from}
                           onChange={changeInputHandler}
                        >
                           {mapOptions()}
                           {/* <option value="BTC">BTC</option>
                        <option value="ETH">ETH</option>
                        <option value="USDT">USDT</option> */}
                        </Form.Control>
                     </Form.Group>
                     <Form.Group as={Col} xs={8}>
                        <Form.Label>{t('form.convert.label.amount')}</Form.Label>
                        <InputGroup>
                           <Form.Control
                              size="lg"
                              name="convert_amount"
                              type="number"
                              step="any"
                              inputMode="decimal"
                              value={form.convert_amount}
                              onChange={changeInputHandler}
                           />
                           <InputGroup.Append>
                              <Button className="background-grad-hor" variant="secondary" onClick={setMaxValue}>
                                 {t('form.convert.button.max')}
                              </Button>
                           </InputGroup.Append>
                        </InputGroup>
                     </Form.Group>
                  </Form.Row>

                  <Form.Row>
                     <Form.Group as={Col} xs={4}>
                        <Form.Label>{t('form.convert.label.to')} :</Form.Label>
                        <Form.Control
                           as="select"
                           size="lg"
                           name="convert_to"
                           value={form.convert_to}
                           onChange={changeInputHandler}
                        >
                           {mapOptions()}
                        </Form.Control>
                     </Form.Group>
                     <Form.Group as={Col} xs={8}>
                        <Form.Label>{t('form.convert.label.amount')}</Form.Label>
                        <Form.Control
                           size="lg"
                           type="number"
                           disabled
                           value={converted}
                        />
                     </Form.Group>
                  </Form.Row>

                  <Row>
                     <Col>
                        <Button type="submit" className="w-100 background-grad-hor-reverce" variant="dark" size="lg">
                           {t('form.convert.button.submit')}
                        </Button>
                     </Col>
                  </Row>
                  {wallets.convertion.message &&
                     <Row className="mt-3">
                        <Col>
                           <Alert
                              className="font-weight-bold text-center"
                              size="xl"
                              variant={wallets.convertion.success ? 'success' : 'danger'}
                           >
                              {MapMessages(wallets.convertion.message)}
                           </Alert>
                        </Col>
                     </Row>
                  }
               </Form>
            </ContentBlock>
         </Styles>

         <h4 className="text-center text-custom-blue my-3">{t('page.convert.title1')}</h4>

         {mapWallets()}
      </Container>
   )
}

const mapStateToProps = state => {
   return {
      rates: state.rates,
      wallets: state.wallets
   }
}

const mapDispatchToProps = {
   requestConvertion
}

export default connect(mapStateToProps, mapDispatchToProps)(PageConvert)
