import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import logo from '../../images/logo_white320x320.png';
import { Link } from 'react-router-dom'
import ContentBlock from '../../components/ContentBlock'
import RegisterForm from '../../components/forms/RegisterForm'
import { authMsgClear, requestRegister } from '../../redux/actions/auth'
import { useTranslation } from 'react-i18next'

const PageSignup = ({ auth, requestRegister, authMsgClear }) => {

   const { t } = useTranslation()

   const { authorized } = auth
   let history = useHistory();
   let location = useLocation();
   let { from } = location.state || { from: { pathname: "/" } };

   authorized && setTimeout(() => {
      history.replace(from)
   }, 1500);

   return (
      <Container className="px-1 background-grad-ver h-100">
         <ContentBlock>
            <Row>
               <Col sm={12} className="text-left pt-3">
                  <Link to={`${process.env.PUBLIC_URL}/welcome`} className="text-white font-weight-bold">
                     <FontAwesomeIcon icon={faChevronLeft} />&nbsp;
                     <span>{t('common.back')}</span>
                  </Link>
               </Col>
            </Row>
            <Row>
               <Col sm={12} className="text-center"><img style={{ maxHeight: '200px' }} src={logo} alt="logo" /></Col>
            </Row>
         </ContentBlock>

         <Row>
            <Col sm={12} className="text-center">
               <h4 className="text-center text-white font-weight-bolder mb-2">{t('page.signup.title')}</h4>
            </Col>
         </Row>
         <ContentBlock className="container-signup mx-n1">
            <Row>
               <Col sm={12} md={6} xl={4} className="mx-auto">
                  <RegisterForm auth={auth} actionSubmit={requestRegister} clearMessage={authMsgClear} />
               </Col>
            </Row>
         </ContentBlock>
      </Container>
   )
}


const mapStateToProps = state => {
   return {
      auth: state.auth
   }
}

const mapDispatchToProps = {
   requestRegister, authMsgClear
}

export default connect(mapStateToProps, mapDispatchToProps)(PageSignup)