export const LOGIN_REQUESTED = "AUTH/LOGIN_REQUESTED"
export const LOGIN_START = "AUTH/LOGIN_START"
export const LOGIN_SUCCESS = "AUTH/LOGIN_SUCCESS"
export const LOGIN_FAILED = "AUTH/LOGIN_FAILED"

export const REGISTER_REQUESTED = "AUTH/REGISTER_REQUESTED"
export const REGISTER_START = "AUTH/REGISTER_START"
export const REGISTER_SUCCESS = "AUTH/REGISTER_SUCCESS"
export const REGISTER_FAILED = "AUTH/REGISTER_FAILED"

export const LOGIN_CHECK_REQUESTED = "AUTH/LOGIN_CHECK_REQUESTED"
export const LOGIN_CHECK_START = "AUTH/LOGIN_CHECK_START"
export const LOGIN_CHECK_SUCCESS = "AUTH/LOGIN_CHECK_SUCCESS"
export const LOGIN_CHECK_FAILED = "AUTH/LOGIN_CHECK_FAILED"

export const AUTH_SET_MESSAGE = "AUTH/SET_MESSAGE"
export const AUTH_CLEAR_MESSAGE = "AUTH/CLEAR_MESSAGE"

export const LOGOUT = "AUTH/LOGOUT"
