import { RATES_LOAD_FAILED, RATES_LOAD_START, RATES_LOAD_SUCCESS, RATES_REQUESTED } from "../types/rates"

export const requestRates = () => {
   return {
      type: RATES_REQUESTED
   }
}

export const ratesLoadStart = () => {
   return {
      type: RATES_LOAD_START
   }
}

export const ratesLoadSuccess = payload => {
   return {
      type: RATES_LOAD_SUCCESS,
      payload
   }
}

export const ratesLoadFaild = () => {
   return {
      type: RATES_LOAD_FAILED
   }
}
