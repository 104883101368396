export const WALLETS_REQUESTED = "WALLET/WALLETS_REQUESTED"
export const WALLETS_LOAD_START = "WALLET/WALLETS_LOAD_START"
export const WALLETS_LOAD_SUCCESS = "WALLET/WALLETS_LOAD_SUCCESS"
export const WALLETS_LOAD_FAILED = "WALLET/WALLETS_LOAD_FAILED"
export const WALLETS_CLEAR_ALL = "WALLET/WALLETS_CLEAR_ALL"

export const CONVERT_REQUESTED = "WALLET/CONVERT_REQUESTED"
export const CONVERT_START = "WALLET/CONVERT_START"
export const CONVERT_SUCCESS = "WALLET/CONVERT_SUCCESS"
export const CONVERT_FAILED = "WALLET/CONVERT_FAILED"
export const CONVERT_CLEAR_MSG = "WALLET/CONVERT_CLEAR_MSG"

export const WITHDRAW_REQUESTED = "WALLET/WITHDRAW_REQUESTED"
export const WITHDRAW_START = "WALLET/WITHDRAW_START"
export const WITHDRAW_SUCCESS = "WALLET/WITHDRAW_SUCCESS"
export const WITHDRAW_FAILED = "WALLET/WITHDRAW_FAILED"
export const WITHDRAW_CLEAR_MSG = "WALLET/WITHDRAW_CLEAR_MSG"
