import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'


const CashDepositInfoModal = ({ show, setShow, ainimation }) => {

   const { t } = useTranslation()

   return (
      <Modal className="text-custom-blue" size="lg" scrollable centered show={show} onHide={() => setShow(false)} animation={ainimation}>
         <Modal.Header closeButton>
            <Modal.Title>{t('modal.cashdeposit.header.title')}</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <p>{t('modal.cashdeposit.body.txt1')}</p>
            <p>{t('modal.cashdeposit.body.txt2')}</p>

            <h5>{t('modal.cashdeposit.body.txt3')}</h5>
            <p>
               <ol>
                  <li>{t('modal.cashdeposit.body.txt4')}</li>
                  <li>{t('modal.cashdeposit.body.txt5')}</li>
               </ol>
            </p>
            <p>{t('modal.cashdeposit.body.txt6')}</p>

            <h5>{t('modal.cashdeposit.body.txt7')}</h5>
            <p>
               <ol>
                  <li>{t('modal.cashdeposit.body.txt8')}</li>
                  <li>{t('modal.cashdeposit.body.txt9')}</li>
                  <li>{t('modal.cashdeposit.body.txt10')}</li>
                  <li>{t('modal.cashdeposit.body.txt11')}</li>
                  <li>{t('modal.cashdeposit.body.txt12')}</li>
                  <li>{t('modal.cashdeposit.body.txt13')}</li>
               </ol>
            </p>
         </Modal.Body>
         <Modal.Footer className="d-block">
            <div><h6 className="text-center">{t('modal.cashdeposit.footer.title')}</h6></div>
            <div className="d-flex justify-content-around">
               <Button className="mx-1 w-50" variant="success" onClick={() => setShow(false)}>
                  {t('modal.cashdeposit.footer.button.yes')}
               </Button>
               <Button className="mx-1 w-50" variant="danger" onClick={() => setShow(false)}>
                  {t('modal.cashdeposit.footer.button.no')}
               </Button>
            </div>
         </Modal.Footer>
      </Modal>
   )
}

export default CashDepositInfoModal
