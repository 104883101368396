import PageWelcome from "../pages/PageWelcome";
import PageHome from "../pages/PageHome";
import PageConvert from "../pages/PageConvert";
import PageDeposit from "../pages/PageDeposit";
import PageCrypto from "../pages/PageCrypto";
import PageInvite from "../pages/PageInvite";

import PageLogin from "../pages/auth/PageLogin";
import PageLogout from "../pages/auth/PageLogout";
import PageSignup from "../pages/auth/PageSignup";
import PagePassRecover from "../pages/auth/PagePassRecover";
import PageWithdraw from "../pages/PageWithdraw";
import PageWithdrawTerms from "../pages/PageWithdrawTerms";
import { PageMarket } from "../pages/PageMarket";
import PageYieldsStaking from "../pages/savings/PageYieldsStaking";
import PageLiquidityProvider from "../pages/savings/PageLiquidityProvider";

export const routerConfig = {
   public: [
      {
         path: `${process.env.PUBLIC_URL}/login`,
         component: PageLogin,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/signup`,
         component: PageSignup,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/signup/:ref_code`,
         component: PageSignup,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/passrecover`,
         component: PagePassRecover,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/invite`,
         component: PageInvite,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/withdrawterms`,
         component: PageWithdrawTerms,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/welcome`,
         component: PageWelcome,
         exact: true
      }
   ],

   private: [
      {
         path: `${process.env.PUBLIC_URL}/logout`,
         component: PageLogout,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/crypto/:currency`,
         component: PageCrypto,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/convert`,
         component: PageConvert,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/deposit`,
         component: PageDeposit,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/withdraw`,
         component: PageWithdraw,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/yields`,
         component: PageYieldsStaking,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/liquidityprovider`,
         component: PageLiquidityProvider,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/market`,
         component: PageMarket,
         exact: true
      },
      {
         path: `${process.env.PUBLIC_URL}/`,
         component: PageHome,
      }
   ]
}
