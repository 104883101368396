import React from 'react'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import ContentBlock from '../../components/ContentBlock'
import RecoverForm from '../../components/forms/RecoverForm'

import logo from '../../images/logo_white320x320.png';
import { recoverMsgClear, requestRecover } from '../../redux/actions/recover'

const PagePassRecover = ({ auth, recover, requestRecover, recoverMsgClear }) => {

   const { t } = useTranslation()

   const { authorized } = auth
   let history = useHistory();
   let location = useLocation();
   let { from } = location.state || { from: { pathname: "/" } };

   authorized && setTimeout(() => {
      history.replace(from)
   }, 0);

   return (
      <Container className="px-1 background-grad-ver h-100">
         {!authorized &&
            <>
               <ContentBlock>
                  <Row>
                     <Col sm={12} className="text-left pt-3">
                        <Link to={`${process.env.PUBLIC_URL}/login`} className="text-white font-weight-bold">
                           <FontAwesomeIcon icon={faChevronLeft} />&nbsp;
                           <span>{t('common.back')}</span>
                        </Link>
                     </Col>
                  </Row>
                  <Row>
                     <Col sm={12} className="text-center"><img style={{ maxHeight: '200px' }} src={logo} alt="logo" /></Col>
                  </Row>
               </ContentBlock>

               <ContentBlock>
                  <Row>
                     <Col sm={12} md={6} xl={4} className="mx-auto bg-white py-5">

                        <Row>
                           <Col sm={12} className="text-center">
                              <h4 className="text-center text-custom-blue font-weight-bolder mb-2">{t('page.passrecover.title')}</h4>
                           </Col>
                        </Row>

                        <Row>
                           <Col sm={12} >

                              <RecoverForm recover={recover} actionSubmit={requestRecover} clearMessage={recoverMsgClear} />

                           </Col>
                        </Row>

                     </Col>
                  </Row>
               </ContentBlock>
            </>
         }
      </Container>
   )
}

const mapStateToProps = state => {
   return {
      auth: state.auth,
      recover: state.recover
   }
}

const mapDispatchToProps = {
   requestRecover,
   recoverMsgClear
}

export default connect(mapStateToProps, mapDispatchToProps)(PagePassRecover)
