import React from 'react'

export default function ContentBlock({ children, className, ...props }) {
   const defaultClasses = "rounded-lg p-3 mx-1 mb-2";
   return (
      <div className={[defaultClasses, className].join(" ")} {...props}>
         {children}
      </div>
   )
}
