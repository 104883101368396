import React from 'react'
import { Container } from 'react-bootstrap'
import useScript from '../hooks/useScript'

export const PageMarket = () => {

   useScript({
      url: "https://s3.tradingview.com/external-embedding/embed-widget-screener.js",
      body: `{
         "width": "100%",
         "height": "100%",
         "defaultColumn": "overview",
         "screener_type": "crypto_mkt",
         "displayCurrency": "USD",
         "colorTheme": "light",
         "locale": "en"
      }`,
      target: "tradingview-widget-container"
   })

   /*
   useEffect(() => {
      const script = document.createElement('script')
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js"
      script.async = true
      script.text = `{
         "width": "100%",
         "height": "100%",
         "defaultColumn": "overview",
         "screener_type": "crypto_mkt",
         "displayCurrency": "USD",
         "colorTheme": "light",
         "locale": "en"
      }`
      document.body.appendChild(script)
      return () => {
         document.body.removeChild(script)
      }
   }, [])
   */

   return (
      <Container className="px-1 py-3 h-100">
         {/* TradingView Widget BEGIN */}
         <div className="tradingview-widget-container" id="tradingview-widget-container">
            <div className="tradingview-widget-container__widget"></div>
            <div className="tradingview-widget-copyright">
               <a href="https://www.tradingview.com/markets/cryptocurrencies/prices-all/" rel="noopener  noreferrer" target="_blank">
                  <span className="blue-text">Cryptocurrency Markets</span>
               </a> by TradingView
            </div>
         </div>
         {/* TradingView Widget END  */}
      </Container>
   )
}
