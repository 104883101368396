import { call, put, select, takeEvery } from "redux-saga/effects";
import { Api } from "../../Api";
import { infoLoadFaild, infoLoadStart, infoLoadSuccess } from "../actions/crypto";
import { CRYPTO_INFO_REQUESTED } from "../types/crypto";

// worker Saga: will be fired on RATES_REQUESTED actions
function* fetchCryptoInfo(action) {
   yield put(infoLoadStart());
   //yield put(appShowLoader())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.fetchCryptoInfo, token);
      //console.log(result)
      yield put(infoLoadSuccess(result.data));
      //console.log(result.errors || [result.message || 'Login successfull']);
   } catch (e) {
      yield put(infoLoadFaild(e.errors || [e.message || 'Something went wrong']));
      //console.log(e.errors || [e.message || 'Something went wrong']);
   }

   //yield put(appHideLoader())
}

/*
  Starts fetchCryptoInfo on each dispatched `CRYPTO_INFO_REQUESTED` action.
*/
export function* cryptoSagas() {
   yield takeEvery(CRYPTO_INFO_REQUESTED, fetchCryptoInfo);
}
