import {
   RECOVER_CLEAR_MESSAGE,
   RECOVER_SET_MESSAGE,
   RECOVER_FAILED,
   RECOVER_START,
   RECOVER_SUCCESS
} from "../types/recover"

const initialState = {
   success: false,
   loading: false,
   message: null
}

export const recoverReducer = (state = initialState, action) => {
   switch (action.type) {


      case RECOVER_SET_MESSAGE:
         return { ...state, message: action.payload }

      case RECOVER_CLEAR_MESSAGE:
         return { ...state, message: null }

      case RECOVER_START:
         return { ...initialState, loading: true }

      case RECOVER_SUCCESS:
         return { ...state, loading: false, success: true }

      case RECOVER_FAILED:
         return { ...state, loading: false, success: false }

      default: return state
   }
}