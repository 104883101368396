import { faConfluence } from '@fortawesome/free-brands-svg-icons'
import { faChartPie, faHome, faPoll, faUserCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Styles = styled.div`
   .navbar-nav a{
      font-size: 30px;
   }
   .navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
      color: #073aad;
  }
`
const NavBottom = () => {
   return (
      <Styles>
         <Navbar fixed="bottom" variant="light" className="bg-white" >
            <Nav className="w-100 d-flex justify-content-between">
               <Nav.Link
                  as={NavLink}
                  to={`${process.env.PUBLIC_URL}/`}
                  href={`${process.env.PUBLIC_URL}/`}
                  exact
                  className="mx-1"
               >
                  <FontAwesomeIcon icon={faHome} />
               </Nav.Link>
               <Nav.Link
                  as={NavLink}
                  to={`${process.env.PUBLIC_URL}/market`}
                  href={`${process.env.PUBLIC_URL}/market`}
                  className="mx-1"

               >
                  <FontAwesomeIcon icon={faChartPie} />
               </Nav.Link>
               <Nav.Link
                  as={NavLink}
                  to={`${process.env.PUBLIC_URL}/convert`}
                  href={`${process.env.PUBLIC_URL}/convert`}
                  className="mx-1"
               >
                  <FontAwesomeIcon icon={faConfluence} />
               </Nav.Link>
               <Nav.Link
                  as={NavLink}
                  to={`${process.env.PUBLIC_URL}/deposit`}
                  href={`${process.env.PUBLIC_URL}/deposit`}
                  className="mx-1"

               >
                  <FontAwesomeIcon icon={faPoll} />
               </Nav.Link>
               <Nav.Link
                  as={NavLink}
                  to={`${process.env.PUBLIC_URL}/settings`}
                  href={`${process.env.PUBLIC_URL}/settings`}
                  className="mx-1"
                  disabled
               >
                  <FontAwesomeIcon icon={faUserCog} />
               </Nav.Link>
            </Nav>
         </Navbar>
      </Styles>
   )
}

export default NavBottom
