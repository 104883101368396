import {
   RECOVER_CLEAR_MESSAGE,
   RECOVER_SET_MESSAGE,
   RECOVER_FAILED,
   RECOVER_REQUESTED,
   RECOVER_START,
   RECOVER_SUCCESS
} from "../types/recover"

export const recoverMsgSet = payload => {
   return {
      type: RECOVER_SET_MESSAGE,
      payload
   }
}

export const recoverMsgClear = () => {
   return {
      type: RECOVER_CLEAR_MESSAGE
   }
}


export const requestRecover = payload => {
   return {
      type: RECOVER_REQUESTED,
      payload
   }
}

export const recoverStart = () => {
   return {
      type: RECOVER_START
   }
}

export const recoverSuccess = payload => {
   return {
      type: RECOVER_SUCCESS,
      payload
   }
}

export const recoverFaild = payload => {
   return {
      type: RECOVER_FAILED,
      payload
   }
}
