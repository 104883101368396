import { helperHttp } from "./helpers/http"

export const Api = {
   fetchLogin: async (formData) => {
      try {
         const request = helperHttp(null)
         const json = await request('/api/v1/auth/login', JSON.stringify(formData))

         return json

      } catch (e) {
         throw e;
      }

   },

   fetchRegister: async (formData) => {
      try {
         const request = helperHttp(null)
         const json = await request('/api/v1/auth/register', JSON.stringify(formData))

         return json

      } catch (e) {
         throw e;
      }

   },

   fetchRecover: async (formData) => {
      try {
         const request = helperHttp(null)
         const json = await request('/api/v1/auth/recover', JSON.stringify(formData))

         return json

      } catch (e) {
         throw e;
      }

   },

   checkLogin: async token => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/auth/check')

         return json

      } catch (e) {
         throw e;
      }

   },

   fetchRates: async token => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/rates')

         return json

      } catch (e) {
         throw e;
      }
   },

   fetchCryptoInfo: async token => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/crypto/info')

         return json

      } catch (e) {
         throw e;
      }
   },

   fetchWallets: async token => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/wallets/get')

         return json

      } catch (e) {
         throw e;
      }
   },

   fetchConvertion: async (token, formData) => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/wallets/convert', JSON.stringify(formData))

         return json

      } catch (e) {
         throw e;
      }

   },

   fetchWithdraw: async (token, formData) => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/wallets/withdraw', JSON.stringify(formData))

         return json

      } catch (e) {
         throw e;
      }

   },

   fetchDepositTP: async (token, formData) => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/pay/tyktakpay', JSON.stringify(formData))

         return json

      } catch (e) {
         throw e;
      }

   },

   fetchSavings: async token => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/savings/get/all/')

         return json

      } catch (e) {
         throw e;
      }
   },

   fetchSavingWithdraw: async (token, formData) => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/savings/user/withdraw', JSON.stringify(formData))

         return json

      } catch (e) {
         throw e;
      }

   },

   fetchSavingDeposit: async (token, formData) => {
      try {
         const request = helperHttp(token)
         const json = await request('/api/v1/savings/user/deposit', JSON.stringify(formData))

         return json

      } catch (e) {
         throw e;
      }

   },

}
