import { call, put, select, takeEvery } from "redux-saga/effects";
import { Api } from "../../Api";
import { appHideLoader, appShowLoader } from "../actions/app";
import { requestSavings, savingDepositStart, savingDepositSuccess, savingWithdrawFaild, savingWithdrawStart, savingWithdrawSuccess, savingsLoadFaild, savingsLoadStart, savingsLoadSuccess } from "../actions/savings";
import { SAVINGS_DEPOSIT_REQUESTED, SAVINGS_REQUESTED, SAVINGS_WITHDRAW_REQUESTED } from "../types/savings";

// worker Saga: will be fired on SAVINGS_REQUESTED actions
function* fetchSavings(action) {
   yield put(savingsLoadStart());
   //yield put(appShowLoader())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.fetchSavings, token);
      //console.log(result)
      yield put(savingsLoadSuccess(result.data));
      //console.log(result.errors || [result.message || 'Savings loaded successfull']);
   } catch (e) {
      yield put(savingsLoadFaild());
      //console.log(e.errors || [e.message || 'Something went wrong']);
   }

   //yield put(appHideLoader())
}

// worker Saga: will be fired on SAVINGS_WITHDRAW_REQUESTED actions
function* fetchSavingWithdraw(action) {
   yield put(savingWithdrawStart())
   yield put(appShowLoader())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.fetchSavingWithdraw, token, action.payload);
      //console.log(result)
      yield put(savingWithdrawSuccess(result.errors || [result.message]));
      yield put(requestSavings())
      //console.log(result.errors || [result.message || 'Savings loaded successfull']);
   } catch (e) {
      yield put(savingWithdrawFaild(e.errors || [e.message || 'Something went wrong']));
      //console.log(e.errors || [e.message || 'Something went wrong']);
   }

   yield put(appHideLoader())
}

// worker Saga: will be fired on SAVINGS_WITHDRAW_REQUESTED actions
function* fetchSavingDeposit(action) {
   yield put(savingDepositStart())
   yield put(appShowLoader())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.fetchSavingDeposit, token, action.payload);
      //console.log(result)
      yield put(savingDepositSuccess(result.errors || [result.message]));
      yield put(requestSavings())
      //console.log(result.errors || [result.message || 'Savings loaded successfull']);
   } catch (e) {
      yield put(savingWithdrawFaild(e.errors || [e.message || 'Something went wrong']));
      //console.log(e.errors || [e.message || 'Something went wrong']);
   }

   yield put(appHideLoader())
}

/*
  Starts fetchSavings on each dispatched `SAVINGS_REQUESTED` action.
  Starts fetchSavingWithdraw on each dispatched `SAVINGS_WITHDRAW_REQUESTED` action.
  Starts fetchSavingDeposit on each dispatched `SAVINGS_DEPOSIT_REQUESTED` action.
*/
export function* savingsSagas() {
   yield takeEvery(SAVINGS_REQUESTED, fetchSavings);
   yield takeEvery(SAVINGS_WITHDRAW_REQUESTED, fetchSavingWithdraw);
   yield takeEvery(SAVINGS_DEPOSIT_REQUESTED, fetchSavingDeposit);
}
