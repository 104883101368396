import {
   LOGIN_REQUESTED,
   LOGIN_START,
   LOGIN_SUCCESS,
   LOGIN_FAILED,
   LOGIN_CHECK_REQUESTED,
   LOGIN_CHECK_START,
   LOGIN_CHECK_SUCCESS,
   LOGIN_CHECK_FAILED,
   LOGOUT,
   AUTH_SET_MESSAGE,
   AUTH_CLEAR_MESSAGE,
   REGISTER_REQUESTED,
   REGISTER_START,
   REGISTER_SUCCESS,
   REGISTER_FAILED
} from "../types/auth"

export const requestLogin = payload => {
   return {
      type: LOGIN_REQUESTED,
      payload
   }
}

export const loginStart = () => {
   return {
      type: LOGIN_START
   }
}

export const loginSuccess = payload => {
   return {
      type: LOGIN_SUCCESS,
      payload
   }
}

export const loginFaild = () => {
   return {
      type: LOGIN_FAILED
   }
}
export const requestRegister = payload => {
   return {
      type: REGISTER_REQUESTED,
      payload
   }
}

export const registerStart = () => {
   return {
      type: REGISTER_START
   }
}

export const registerSuccess = payload => {
   return {
      type: REGISTER_SUCCESS,
      payload
   }
}

export const registerFaild = () => {
   return {
      type: REGISTER_FAILED
   }
}

export const requestLoginCheck = () => {
   return {
      type: LOGIN_CHECK_REQUESTED
   }
}

export const checkLoginStart = () => {
   return {
      type: LOGIN_CHECK_START
   }
}

export const checkLoginSuccess = payload => {
   return {
      type: LOGIN_CHECK_SUCCESS,
      payload
   }
}

export const checkLoginFaild = () => {
   return {
      type: LOGIN_CHECK_FAILED
   }
}

export const authMsgSet = payload => {
   return {
      type: AUTH_SET_MESSAGE,
      payload
   }
}

export const authMsgClear = () => {
   return {
      type: AUTH_CLEAR_MESSAGE
   }
}

export const logout = () => {
   return {
      type: LOGOUT
   }
}
