import { call, put, select, takeEvery } from "redux-saga/effects";
import { Api } from "../../Api";
import { payTpFaild, payTpSuccess, startTpPay } from "../actions/pay";
import { appHideLoader, appShowLoader } from "../actions/app";
import { PAY_TP_REQUESTED } from "../types/pay";

// worker Saga: will be fired on PAY_TP_REQUESTED actions
function* fetchTpPay(action) {
   yield put(appShowLoader())
   yield put(startTpPay())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.fetchDepositTP, token, action.payload);
      console.log(result)
      yield put(payTpSuccess(result.data.tx_link));
      //console.log(result.errors || [result.message || 'Login successfull']);
   } catch (e) {
      yield put(payTpFaild(e.errors || [e.message || 'Something went wrong']));
      //console.log(e.errors || [e.message || 'Something went wrong']);
   }

   yield put(appHideLoader())
}

/*
  Starts fetchLogin on each dispatched `PAY_TP_REQUESTED` action.
*/
export function* paySagas() {
   yield takeEvery(PAY_TP_REQUESTED, fetchTpPay);
}
