import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

const Styles = styled.div`
.loader{
   position: absolute;
   top:0;
   left:0;
   bottom:0;
   right:0;
   z-index: 999999;
}
.loader__overlay{
   background-color: #000000;
   opacity: 0.5;
   position: absolute;
   top:0;
   left:0;
   bottom:0;
   right:0;
}
`

export const Loader = () => {
   return (
      <Styles>
         <div className="loader d-flex justify-content-center align-items-center">
            <div className="loader__overlay"></div>
            <Spinner animation="border" role="status" size="xl">
               <span className="sr-only">Loading...</span>
            </Spinner>
         </div>
      </Styles>
   )
}