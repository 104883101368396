import { call, put, takeEvery } from 'redux-saga/effects'
import { Api } from '../../Api'
import { appHideLoader, appShowLoader } from '../actions/app';
import { recoverMsgSet, recoverFaild, recoverStart, recoverSuccess } from '../actions/recover';
import { RECOVER_REQUESTED } from '../types/recover';

// worker Saga: will be fired on LOGIN_REQUESTED actions
function* fetchRecover(action) {
   yield put(recoverStart());
   yield put(appShowLoader())

   try {
      const result = yield call(Api.fetchRecover, action.payload);
      yield put(recoverSuccess(result.data));
      yield put(recoverMsgSet(result.errors || [result.message || 'Recover successfull']));
   } catch (e) {
      yield put(recoverFaild());
      yield put(recoverMsgSet(e.errors || [e.message || 'Something went wrong']));
   }

   yield put(appHideLoader())
}

/*
  Starts fetchRecover on each dispatched `RECOVER_REQUESTED` action.
*/
export function* recoverSagas() {
   yield takeEvery(RECOVER_REQUESTED, fetchRecover);
}
