import { RATES_LOAD_FAILED, RATES_LOAD_START, RATES_LOAD_SUCCESS } from "../types/rates"


const currenciesNames = {
   BTC: 'Bitcoin',
   ETH: 'Ethereum',
   JOSC: 'Jony Coin',
   USDT: 'Tether',
   AAVE: 'Aave',
   BCH: 'Bitcoin Cash',
   BNB: 'Binance Coin',
   BSV: 'Bitcoin SV',
   BUSD: 'Binance USD',
   DOGE: 'Dogecoin',
   ETC: 'Ethereum Classic',
   FIL: 'Filecoin',
   FTT: 'FTX Token',
   HT: 'Huobi Token',
   LTC: 'Litecoin',
   LUNA: 'Terra',
   MATIC: 'Polygon',
   MIOTA: 'IOTA',
   NEO: 'Neo',
   SHIB: 'SHIBA INU',
   THETA: 'THETA',
   TRX: 'TRON',
   WBTC: 'Wrapped Bitcoin',
   XMR: 'Monero',
   XRP: 'XRP'
}


const initialState = {
   loading: false,
   data: {},
   names: currenciesNames
}

export const ratesReducer = (state = initialState, action) => {
   switch (action.type) {
      case RATES_LOAD_START:
         return { ...state, loading: true }

      case RATES_LOAD_SUCCESS:
         return { ...state, loading: false, data: { ...state.data, ...reduceRates(action.payload) } }

      case RATES_LOAD_FAILED:
         return { ...state, loading: false }

      default: return state
   }
}

const reduceRates = (rates) => {
   for (const key_crypto in rates) {
      if (Object.hasOwnProperty.call(rates, key_crypto)) {
         const crypto = rates[key_crypto]
         for (const key_currency in crypto) {
            if (Object.hasOwnProperty.call(crypto, key_currency)) {
               const currency = crypto[key_currency]
               for (const key in currency) {
                  if (Object.hasOwnProperty.call(currency, key)) {
                     if (key !== 'last_updated')
                        currency[key] = parseFloat(currency[key])
                  }
               }
            }
         }
      }
   }

   return rates
}
