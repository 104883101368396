import { PAY_LINK_CLEAR, PAY_TP_FAILED, PAY_TP_REQUESTED, PAY_TP_SUCCESS, PAY_TP_START } from "../types/pay"

const initialState = {
   loading: false,
   payLink: null,
   errors: null
}

export const depositReducer = (state = initialState, action) => {
   switch (action.type) {
      case PAY_TP_REQUESTED:
         return { ...initialState }
      case PAY_TP_START:
         return { ...state, loading: true }

      case PAY_TP_SUCCESS:
         return { ...initialState, payLink: action.payload }

      case PAY_TP_FAILED:
         return { ...initialState, errors: action.payload }

      case PAY_LINK_CLEAR:
         return { ...initialState }

      default: return state
   }
}