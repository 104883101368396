import React, { useRef, useState } from 'react'
import { Button, Col, Row, Toast } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { QRCode } from 'react-qr-svg'

const WalletQrCode = ({ address }) => {

   const { t } = useTranslation()

   const [copySuccess, setCopySuccess] = useState(false);
   const textRef = useRef(null);

   function copyToClipboard() {

      if (!navigator.clipboard) {
         // use old commandExec() way

         const textArea = document.createElement("textarea");
         textArea.value = textRef.current.getAttribute('data-address');
         document.body.appendChild(textArea);
         textArea.select();
         document.execCommand("Copy");
         textArea.remove();

         setCopySuccess(true)
         console.log('Copied!')

      } else {
         navigator.clipboard.writeText(textRef.current.getAttribute('data-address'))
            .then(function () {
               setCopySuccess(true)
               console.log('Copied to clipboard!')
            })
            .catch(function () {
               setCopySuccess(false)
               console.log('Copy to clipboard error!')
            });
      }
   }

   return (
      <>
         <Toast onClose={() => setCopySuccess(false)} show={copySuccess} animation={true} delay={2000} autohide>
            <Toast.Body><div className="text-center">Copied to Clipboard!</div></Toast.Body>
         </Toast>

         <Row className="mb-4">
            <Col className="text-center">{address}</Col>
         </Row>
         <Row className="mb-4">
            <Col className="text-center" >
               <Button
                  ref={textRef}
                  size="lg"
                  variant="dark"
                  data-address={address}
                  className="w-100 background-grad-hor"
                  onClick={copyToClipboard}
               >{t('qrcode.button.copy')}</Button>
            </Col>
         </Row>
         <Row>
            <Col className="text-center">
               <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#1461af"
                  level="Q"
                  style={{ width: '90%' }}
                  value={address}
               />
            </Col>
         </Row>
      </>
   )
}

export default WalletQrCode
