import React from 'react'
import { Col, Row } from 'react-bootstrap'

const CryptoInfoBlock = ({ info, ...rest }) => {
   return (
      <div {...rest}>
         <Row>
            <Col xs={4} className="text-custom-blue" style={{ fontSize: '12px' }}>About :</Col>
            <Col xs={8}>{info.about}</Col>
         </Row>
         <Row>
            <Col xs={4} className="text-custom-blue" style={{ fontSize: '12px' }}>Website :</Col>
            <Col xs={8}><a href={info.website} target="_blank" rel="noreferrer">Website Link</a></Col>
         </Row>
         <Row>
            <Col xs={4} className="text-custom-blue" style={{ fontSize: '12px' }}>Whitepaper :</Col>
            <Col xs={8}><a href={info.white_paper} target="_blank" rel="noreferrer">Link to Whitepaper</a></Col>
         </Row>
      </div>
   )
}

export default CryptoInfoBlock