import {
   SAVINGS_CLEAR_ALL,
   SAVINGS_DEPOSIT_CLEAR_MSG,
   SAVINGS_DEPOSIT_FAILED,
   SAVINGS_DEPOSIT_START,
   SAVINGS_DEPOSIT_SUCCESS,
   SAVINGS_LOAD_FAILED,
   SAVINGS_LOAD_START,
   SAVINGS_LOAD_SUCCESS,
   SAVINGS_WITHDRAW_CLEAR_MSG,
   SAVINGS_WITHDRAW_FAILED,
   SAVINGS_WITHDRAW_START,
   SAVINGS_WITHDRAW_SUCCESS
} from "../types/savings"

const initialState = {
   loading: false,
   options: {},
   user: {},
   deposit: {
      message: null,
      success: false
   },
   withdraw: {
      message: null,
      success: false
   }
}

export const savingsReducer = (state = initialState, action) => {
   switch (action.type) {
      /* SAVINGS LOAD */
      case SAVINGS_LOAD_START:
         return { ...state, loading: true }

      case SAVINGS_LOAD_SUCCESS:
         return { ...state, loading: false, options: reduceOptions(action.payload.options), user: reduceUser(action.payload.user) }

      case SAVINGS_LOAD_FAILED:
         return { ...state, loading: false }

      case SAVINGS_CLEAR_ALL:
         return { ...initialState }

      /* WITHDRAW */
      case SAVINGS_WITHDRAW_START:
         return { ...state, withdraw: { ...state.withdraw, message: null, success: false } }

      case SAVINGS_WITHDRAW_SUCCESS:
         return { ...state, withdraw: { ...state.withdraw, message: action.payload, success: true } }

      case SAVINGS_WITHDRAW_FAILED:
         return { ...state, withdraw: { ...state.withdraw, message: action.payload, success: false } }

      case SAVINGS_WITHDRAW_CLEAR_MSG:
         return { ...state, withdraw: { ...state.withdraw, message: null } }

      /* DEPOSIT */
      case SAVINGS_DEPOSIT_START:
         return { ...state, withdraw: { ...state.deposit, message: null, success: false } }

      case SAVINGS_DEPOSIT_SUCCESS:
         return { ...state, withdraw: { ...state.deposit, message: action.payload, success: true } }

      case SAVINGS_DEPOSIT_FAILED:
         return { ...state, withdraw: { ...state.deposit, message: action.payload, success: false } }

      case SAVINGS_DEPOSIT_CLEAR_MSG:
         return { ...state, withdraw: { ...state.deposit, message: null } }


      default: return state
   }
}

function reduceOptions(options) {
   if ((typeof options) == "object") {
      for (const key in options) {

         if (options.hasOwnProperty(key)) {
            options[key].length && options[key].forEach((opt, index) => {
               opt.ID = parseInt(opt.ID)
               opt.created = new Date(opt.created)
               opt.updated = opt.updated != null ? new Date(opt.updated) : null
               opt.min_amount = opt.min_amount != null ? parseFloat(opt.min_amount) : null
               opt.max_amount = opt.max_amount != null ? parseFloat(opt.max_amount) : null
               opt.months = opt.months != null ? parseInt(opt.months) : null
               opt.percent_year = opt.percent_year != null ? parseFloat(opt.percent_year) : null
               opt.status = parseInt(opt.status)
               opt.type_id = parseInt(opt.type_id)

               options[key][index] = opt
            })

         }
      }
   }

   return options
}

function reduceUser(savings) {
   if ((typeof savings) == "object") {
      for (const key in savings) {

         if (savings.hasOwnProperty(key)) {
            savings[key].length && savings[key].forEach((sav, index) => {
               sav.ID = parseInt(sav.ID)
               sav.amount = sav.amount != null ? parseFloat(sav.amount) : null
               sav.created = new Date(sav.created)
               sav.updated = sav.updated != null ? new Date(sav.updated) : null
               sav.exp_date = sav.exp_date != null ? new Date(sav.exp_date) : null
               sav.last_payed = sav.last_payed != null ? new Date(sav.last_payed) : null
               sav.dividend = sav.dividend != null ? parseFloat(sav.dividend) : null
               sav.months = sav.months != null ? parseInt(sav.months) : null
               sav.percent_year = sav.percent_year != null ? parseFloat(sav.percent_year) : null
               sav.active = parseInt(sav.active)
               sav.type_id = parseInt(sav.type_id)
               sav.option_id = parseInt(sav.option_id)

               savings[key][index] = sav
            })

         }
      }
   }

   return savings
}
