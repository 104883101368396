import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Col, Container, Form, InputGroup, ListGroup, Row, Tab, Tabs } from 'react-bootstrap'
import { connect } from 'react-redux';
import WalletQrCode from '../components/WalletQrCode';
import ContentBlock from '../components/ContentBlock'
import { payLinkClear, requestTpPay } from '../redux/actions/pay';
import TotalAmount from '../components/wallet/TotalAmount';
import styled from 'styled-components'
import { isSafari } from 'react-device-detect';
import MapMessages from '../components/MapMessages';
import { useTranslation } from 'react-i18next';
import CashDepositInfoModal from '../components/CashDepositInfoModal';

const Styles = styled.div`
   *{
      border-color: #1461af50 ;
   }
   .nav-pills > .nav-link.active, .nav-pills > .nav-link.active:hover, .nav-pills > li > a:hover,
   .nav-pills > .nav-link, .nav-pills > .nav-link.active, .nav-pills > li > a{
      color: #073aad !important;
   }
   .nav-pills > .nav-link.active, .nav-pills > .nav-link.active:hover, .nav-pills > li > a:hover{
      border-bottom-color: #073aad !important;
   }
   .list-group{
      max-height: 25vh;
      overflow-x: hidden;
      overflow-y: auto;
   }
   .list-group-item.active {
      color: inherit !important;
      background-color: #1461af1f !important;
      border-color: #d6d8db;
   }
`

const PageDeposit = ({ wallets, rates, deposit, requestTpPay, payLinkClear }) => {

   const { t } = useTranslation()

   const [key, setKey] = useState('fiat')
   const [fiatKey, setFiatKey] = useState('btc')
   const [fiatUsdValue, setFiatUsdValue] = useState(10)

   const [cryptoKey, setCryptoKey] = useState('btc')

   const [ATMInfoModal, setATMInfoModal] = useState(false)

   const { payLink, errors } = deposit

   useEffect(() => {
      payLink && (isSafari ? window.location.href = payLink : window.open(payLink))
      payLinkClear()
   }, [payLink, payLinkClear])

   const submitHandler = e => {
      e.preventDefault()
      const form = {
         amount: fiatUsdValue,
         currency: fiatKey
      }
      requestTpPay(form)
   }

   const mapFiats = () => {
      const wArr = []

      for (const curr in rates.data) {
         if (Object.hasOwnProperty.call(wallets, curr)) {
            if (undefined !== wallets[curr].settings.is_buyable && !wallets[curr].settings.is_buyable) continue

            const logo = <img style={{ maxHeight: '20px', width: '25px' }} alt={curr} src={`icons/${curr.toLowerCase()}.svg`} />
            const element =
               (
                  <ListGroup.Item
                     key={`${curr}`}
                     eventKey={curr.toLowerCase()}
                     onSelect={(k) => setFiatKey(k)}
                  >{logo} {rates.names[curr]} ({curr})</ListGroup.Item>
               )

            wArr.push(element)
         }
      }

      return wArr
   }

   const mapCryptos = () => {
      const wArr = []

      for (const curr in rates.data) {
         if (
            Object.hasOwnProperty.call(wallets, curr)
            && wallets[curr].address?.number?.length
         ) {
            if (undefined !== wallets[curr].settings.is_buyable && !wallets[curr].settings.is_buyable) continue

            const logo = <img style={{ maxHeight: '25px', width: '25px' }} alt={curr} src={`icons/${curr.toLowerCase()}.svg`} />
            const element =
               (
                  <ListGroup.Item
                     key={`${curr}`}
                     eventKey={curr.toLowerCase()}
                     onSelect={(k) => setCryptoKey(k)}
                  >{logo} {rates.names[curr]} ({curr})</ListGroup.Item>
               )

            wArr.push(element)
         }
      }

      return wArr
   }

   return (
      <Container className="px-1 text-custom-blue">
         <CashDepositInfoModal show={ATMInfoModal} setShow={setATMInfoModal} ainimation={false} />

         <ContentBlock>
            <TotalAmount />
         </ContentBlock>

         <Row className="py-2">
            <Col><h4 className="text-center">{t('page.deposit.title')}</h4></Col>
         </Row>
         <Row>
            <Col xs={12} md={6} xl={4} className="mx-auto">
               <Styles>
                  <Tabs
                     onSelect={(k) => setKey(k)}
                     className="nav-justified mb-1"
                     activeKey={key}
                     defaultActiveKey="fiat"
                     transition={false}
                     variant="pills"
                     id="deposit-tabs"
                  >
                     <Tab eventKey="fiat" title={t('page.deposit.tab.fiat')}>
                        <Row className="px-4 my-4">
                           <Col >
                              <ListGroup variant="flush" activeKey={fiatKey}>{mapFiats()}</ListGroup>
                           </Col>
                        </Row>
                        <Row className="px-2 my-4">
                           <Col>
                              <div className="text-center">{t('page.deposit.subtitle1')}</div>

                              <ContentBlock className="border m-2">
                                 {errors && (
                                    <Alert variant="danger">{MapMessages(errors)}</Alert>
                                 )}
                                 <Form onSubmit={submitHandler}>
                                    <Form.Row>
                                       <Col>
                                          <InputGroup className="mb-3">
                                             <InputGroup.Prepend>
                                                <InputGroup.Text id="deposit-form-addon-value">
                                                   <FontAwesomeIcon icon={faDollarSign} />
                                                </InputGroup.Text>
                                             </InputGroup.Prepend>
                                             <Form.Control
                                                aria-describedby="deposit-form-addon-value"
                                                type="number"
                                                name="amount"
                                                value={fiatUsdValue}
                                                onChange={(e) => setFiatUsdValue(e.target.value)}
                                             />
                                          </InputGroup>
                                          <Form.Control
                                             type="hidden"
                                             name="currency"
                                             value={fiatKey}
                                          />
                                       </Col>
                                    </Form.Row>
                                    <Form.Row>
                                       <Col>
                                          <Button className="w-100 background-grad-hor-reverce" type="submit" variant="dark">
                                             {t('page.deposit.button.paytp')}
                                          </Button>
                                       </Col>
                                    </Form.Row>
                                 </Form>
                              </ContentBlock>
                              {fiatKey === "btc" && wallets.BTC?.address?.number?.length && (
                                 <>
                                    <div className="text-center">
                                       {t('page.deposit.subtitle2')}
                                       &nbsp;
                                       <FontAwesomeIcon
                                          icon={faQuestionCircle}
                                          title={t('page.deposit.atmhelp')}
                                          onClick={() => setATMInfoModal(!ATMInfoModal)}
                                       />
                                    </div>

                                    <div className="blok w-100 mx-auto mt-4">
                                       <div className="mb-4">
                                          <Button
                                             size="lg"
                                             className="text-center background-grad-hor-reverce w-100"
                                             target="__blank"
                                             href="https://www.google.com/search?q=bitcoin+atm+near+me"
                                          > {t('page.deposit.button.findatm')}</Button>
                                       </div>
                                       <WalletQrCode address={wallets.BTC.address.number} />
                                    </div>
                                 </>
                              )}
                           </Col>
                        </Row>
                     </Tab>
                     <Tab eventKey="crypto" title={t('page.deposit.tab.crypto')}>
                        <Row className="px-4 my-4">
                           <Col>
                              <ListGroup variant="flush" activeKey={cryptoKey}>{mapCryptos()}</ListGroup>
                           </Col>
                           <Col className="my-3">
                              {/* <h6 className="text-center mb-4">Deposit {cryptoKey.toUpperCase()}</h6> */}
                              <WalletQrCode address={wallets[cryptoKey.toUpperCase()]?.address?.number ?? ""} />
                           </Col>

                        </Row>
                     </Tab>
                  </Tabs>
               </Styles>
            </Col>
         </Row>
      </Container>
   )
}

const mapStateToProps = state => {
   return {
      wallets: state.wallets,
      rates: state.rates,
      deposit: state.deposit,
   }
}

const mapDispatchToProps = {
   requestTpPay, payLinkClear
}

export default connect(mapStateToProps, mapDispatchToProps)(PageDeposit)
