import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ContentBlock from '../components/ContentBlock'
import logo from '../images/logo_white320x320.png'
import {
   FacebookShareButton,
   FacebookIcon,
   TwitterShareButton,
   TwitterIcon,
   TelegramShareButton,
   TelegramIcon,
   WhatsappShareButton,
   WhatsappIcon,
   LinkedinShareButton,
   LinkedinIcon,
   EmailShareButton,
   EmailIcon
} from 'react-share'

const PageInvite = () => {

   const { t } = useTranslation()

   const { authorized, user } = useSelector((state) => state.auth)
   let history = useHistory();

   !authorized && setTimeout(() => {
      history.replace({ pathname: "/login" })
   }, 0);

   const shareBtn = {
      title: t('page.invite.button.title'),
      hashtag: "AxsesTrade",
      mailBody: ""
   }

   return (
      <Container className="px-1 background-grad-ver h-100">
         <ContentBlock>
            <Row>
               <Col sm={12} className="text-center"><img style={{ maxHeight: '200px' }} src={logo} alt="logo" /></Col>
            </Row>
         </ContentBlock>

         <ContentBlock>
            <Row>
               <Col sm={12} className="text-center">
                  <h4 className="text-center text-warning">{t('page.invite.title')}</h4>
                  <h4 className="text-center text-white font-weight-bolder mb-2">{t('page.invite.title1')}</h4>
               </Col>
            </Row>
            <Row className="mt-3">
               <div className="d-flex justify-content-center w-100">
                  <div className="share-btn mx-1">
                     <FacebookShareButton
                        url={`https://axsestrade.com/signup/${user?.ReferalCode}`}
                        hashtag={shareBtn.hashtag}
                        quote={shareBtn.title}
                     >
                        <FacebookIcon size={32} round />
                     </FacebookShareButton>
                  </div>
                  <div className="share-btn mx-1">
                     <TwitterShareButton
                        url={`https://axsestrade.com/signup/${user?.ReferalCode}`}
                        title={shareBtn.title}
                     >
                        <TwitterIcon size={32} round />
                     </TwitterShareButton>
                  </div>
                  <div className="share-btn mx-1">
                     <TelegramShareButton
                        url={`https://axsestrade.com/signup/${user?.ReferalCode}`}
                        title={shareBtn.title}
                     >
                        <TelegramIcon size={32} round />
                     </TelegramShareButton>
                  </div>
                  <div className="share-btn mx-1">
                     <WhatsappShareButton
                        url={`https://axsestrade.com/signup/${user?.ReferalCode}`}
                        title={shareBtn.title}
                        separator=":: "
                     >
                        <WhatsappIcon size={32} round />
                     </WhatsappShareButton>
                  </div>
                  <div className="share-btn mx-1">
                     <LinkedinShareButton url={`https://axsestrade.com/signup/${user?.ReferalCode}`} >
                        <LinkedinIcon size={32} round />
                     </LinkedinShareButton>
                  </div>
                  <div className="share-btn mx-1">
                     <EmailShareButton
                        url={`https://axsestrade.com/signup/${user?.ReferalCode}`}
                        subject={shareBtn.title}
                        body={shareBtn.mailBody}
                     >
                        <EmailIcon size={32} round />
                     </EmailShareButton>
                  </div>
               </div>
            </Row>
         </ContentBlock>

      </Container>
   )
}

export default PageInvite
