import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Alert, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import ContentBlock from '../../components/ContentBlock'
import MapMessages from '../../components/MapMessages'
import TotalAmount from '../../components/wallet/TotalAmount'
import UniversalCompactView from '../../components/wallet/UniversalCompactView'
import { requestSavings, requestSavingDeposit } from '../../redux/actions/savings'

const Styles = styled.div`
   *{
      border-color: #1461af60 !important;
   }
`

const PageYieldsStaking = ({ rates, wallets, savings, requestSavings, requestSavingDeposit }) => {

   let history = useHistory()
   let location = useLocation()

   const [path, setPath] = useState(location.pathname)

   useEffect(() => {
      history.replace({ pathname: path })
   }, [history, path])

   const { t } = useTranslation()

   const selectOptionRef = useRef(null);

   const initialState = {
      save_crypto: 'BTC',
      save_amount: 0,
      save_option: 0
   }

   const [form, setform] = useState(initialState)

   const setMaxValue = useCallback(() => {
      setform({ ...form, save_amount: wallets[form.save_crypto]?.value?.available ?? 0 })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [form.save_crypto, wallets])


   const selectSavingOption = useCallback(() => {

      let selectedOptionId = form.save_option ?? 0

      if (selectedOptionId === 0) selectedOptionId = savings.options.YST?.at(savings.options.YST.length - 1).ID ?? 0

      selectOptionRef.current.value = selectedOptionId
      setform({ ...form, save_option: selectedOptionId })

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [form.save_amount, form.save_crypto, form.save_option, savings.options.YST])

   const assetsWallets = () => {

      let assetsObj = Object.keys(wallets)
         .filter(curr => wallets[curr].value?.available > 0)
         .sort((b, a) => {
            return wallets[a].value.available * rates.data[a]?.USD?.price - wallets[b].value.available * rates.data[b]?.USD?.price
         })
         .reduce((acc, key) => {
            acc[key] = wallets[key]
            return acc
         }, {})

      //console.log(assetsObj)
      return assetsObj
   }

   const mapSavingOptions = (type_code) => {
      const wArr = []

      const optionsArr = savings.options[type_code] ?? []

      optionsArr.forEach(opt => {

         const element = (<option key={`${opt.ID}`} value={opt.ID}>{opt.name}</option>)

         wArr.push(element)
      });

      return wArr
   }

   const mapCurrencyOptions = () => {
      const wArr = []

      const walletsObj = assetsWallets()

      for (const curr in walletsObj) {
         if (Object.hasOwnProperty.call(wallets, curr)) {

            if (wallets[curr].value.available === 0)
               continue

            const element = (<option key={`${curr}`} value={curr}>{curr}</option>)

            wArr.push(element)
         }
      }

      return wArr
   }

   const mapWallets = () => {
      const wArr = []

      const walletsObj = assetsWallets()

      for (const curr in walletsObj) {
         if (Object.hasOwnProperty.call(rates.data, curr)) {

            if (wallets[curr].value.available === 0)
               continue

            const logo = <img alt={curr} src={`icons/${curr.toLowerCase()}.svg`} />
            const element = (
               <ContentBlock key={curr} className="border border-dark m-2">
                  <UniversalCompactView
                     rates={rates.data[curr]}
                     wallet={wallets[curr]}
                     code={curr}
                     title={rates.names[curr]}
                     logo={logo}
                     onClick={() => setPath(`/crypto/${curr.toUpperCase()}`)}
                  />
               </ContentBlock>
            )

            wArr.push(element)
         }
      }

      return wArr
   }

   const mapSavings = () => {
      const wArr = []

      if (Array.isArray(savings.user.YST)) {
         savings.user?.YST?.forEach(sav => {
            const element = (
               <ContentBlock className="border border-1 m-2">
                  <Row key={sav.ID}>
                     <Col>{sav.crypto_code ?? ''}<br />{sav.amount?.toFixed(3) ?? ''}</Col>
                     <Col className='text-center'>{sav.percent_year ?? ''}%</Col>
                     {/* <Col>{sav.exp_date ? sav.exp_date.toISOString().slice(0, 10) : ''}</Col> */}
                     <Col className='text-center'>
                        {sav.exp_date ? ("0" + (sav.exp_date.getMonth() + 1)).slice(-2) + "-" + ("0" + (sav.exp_date.getDate())).slice(-2) : ''}
                        <br />
                        {sav.exp_date ? sav.exp_date.getFullYear() : ""}
                     </Col>
                  </Row>
               </ContentBlock>
            )
            wArr.push(element)
         })
      }

      return wArr
   }

   const changeInputHandler = e => {
      const { type, checked, name, value } = e.target;
      setform(prev => ({
         ...prev, ...{
            [name]: type === 'checkbox' ? checked : value
         }
      }))
   }

   const submitHandler = e => {
      e.preventDefault()
      requestSavingDeposit(form)
   }

   useEffect(() => {
      requestSavings()
   }, [requestSavings])

   useEffect(() => {
      if (wallets[form.save_crypto]?.value ?? false) {
         if (form.save_amount > wallets[form.save_crypto].value.available) {
            setform({ ...form, save_amount: wallets[form.save_crypto].value.available })
         }
      }
   }, [form, wallets])

   useEffect(() => {
      setMaxValue()

   }, [form.save_crypto, wallets, savings, setMaxValue])

   useEffect(() => {
      selectSavingOption()
   }, [form.save_amount, form.save_crypto, savings, selectSavingOption])

   return (
      <Container className="px-1 text-custom-blue">
         <ContentBlock>
            <TotalAmount />
         </ContentBlock>

         <Styles>
            <ContentBlock className="border border-1 m-2">
               <Form onSubmit={submitHandler}>
                  <Form.Row>
                     <Form.Group as={Col} xs={4}>
                        <Form.Label>{t('form.savings.label.from')} :</Form.Label>
                        <Form.Control
                           as="select"
                           size="lg"
                           name="save_crypto"
                           value={form.save_crypto}
                           onChange={changeInputHandler}
                        >
                           {mapCurrencyOptions()}
                        </Form.Control>
                     </Form.Group>
                     <Form.Group as={Col} xs={8}>
                        <Form.Label>{t('form.savings.label.amount')}</Form.Label>
                        <InputGroup>
                           <Form.Control
                              size="lg"
                              name="save_amount"
                              type="number"
                              step="any"
                              inputMode="decimal"
                              value={form.save_amount}
                              onChange={changeInputHandler}
                           />
                           <InputGroup.Append>
                              <Button className="background-grad-hor" variant="secondary" onClick={setMaxValue}>
                                 {t('form.savings.button.max')}
                              </Button>
                           </InputGroup.Append>
                        </InputGroup>
                     </Form.Group>
                  </Form.Row>

                  <Form.Row>
                     <Form.Group as={Col} xs={12}>
                        <Form.Label>{t('form.savings.label.options_yst')} :</Form.Label>
                        <Form.Control
                           as="select"
                           size="lg"
                           name="save_option"
                           value={form.save_option}
                           onChange={changeInputHandler}
                           style={{ fontSize: '1rem' }}
                           ref={selectOptionRef}
                        >
                           {mapSavingOptions("YST")}
                        </Form.Control>
                     </Form.Group>
                  </Form.Row>

                  <Row>
                     <Col>
                        <Button type="submit" className="w-100 background-grad-hor-reverce" variant="dark" size="lg">
                           {t('form.savings.button.submit')}
                        </Button>
                     </Col>
                  </Row>
                  {savings.deposit.message &&
                     <Row className="mt-3">
                        <Col>
                           <Alert
                              className="font-weight-bold text-center"
                              size="xl"
                              variant={savings.deposit.success ? 'success' : 'danger'}
                           >
                              {MapMessages(savings.deposit.message)}
                           </Alert>
                        </Col>
                     </Row>
                  }
                  {savings.withdraw.message &&
                     <Row className="mt-3">
                        <Col>
                           <Alert
                              className="font-weight-bold text-center"
                              size="xl"
                              variant={savings.withdraw.success ? 'success' : 'danger'}
                           >
                              {MapMessages(savings.withdraw.message)}
                           </Alert>
                        </Col>
                     </Row>
                  }
               </Form>
            </ContentBlock>

            {savings.user?.YST?.length && mapSavings()}
         </Styles>


         <h4 className="text-center text-custom-blue my-3">{t('page.convert.title1')}</h4>

         {mapWallets()}

      </Container>
   )
}


const mapStateToProps = state => {
   return {
      rates: state.rates,
      wallets: state.wallets,
      savings: state.savings
   }
}

const mapDispatchToProps = {
   requestSavings,
   requestSavingDeposit
}

export default connect(mapStateToProps, mapDispatchToProps)(PageYieldsStaking)