import React, { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import MapMessages from '../MapMessages'

const RecoverForm = ({ recover, actionSubmit, clearMessage }) => {

   const { t } = useTranslation()

   const initialState = {
      email: ''
   }
   const [form, setform] = useState(initialState)

   useEffect(() => {
      clearMessage()
   }, [clearMessage])

   const changeInputHandler = e => {
      const { type, checked, name, value } = e.target;
      setform(prev => ({
         ...prev, ...{
            [name]: type === 'checkbox' ? checked : value
         }
      }))
   }

   const submitHandler = e => {
      e.preventDefault()
      clearMessage()
      actionSubmit(form)
   }

   return (
      <>
         <Row>
            <Col>
               {recover.message
                  ? <Alert variant={recover.success ? 'success' : 'danger'}>{MapMessages(recover.message)}</Alert>
                  : <div>&nbsp;</div>
               }
            </Col>
         </Row>

         {(!recover.success && !recover.loading) && (
            <Form onSubmit={submitHandler}>
               <Form.Row>
                  <Col>
                     <Form.Group className="mb-3">
                        <Form.Control
                           placeholder={t('form.recover.label.email')}
                           aria-label="Email"
                           aria-describedby="recoverpass-form-addon-email"
                           name="email"
                           type="email"
                           size="lg"
                           value={form.email}
                           onChange={changeInputHandler}
                        />
                        {/* {recover.message && recover.message.email !== undefined
                           && <Form.Control.Feedback style={{ display: 'block' }} type="invalid">{recover.message.email}</Form.Control.Feedback>} */}
                     </Form.Group>
                  </Col>
               </Form.Row>

               <Form.Row>
                  <Col className="text-center">
                     <Button
                        variant="secondary"
                        size="lg"
                        className="w-50 font-weight-bold font-size-large background-grad-hor d-block ml-auto"
                        type="submit">
                        {t('form.recover.button.submit')}
                     </Button>
                  </Col>
               </Form.Row>

            </Form>
         )}
      </>
   )
}

export default RecoverForm
