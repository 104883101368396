import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import ContentBlock from '../components/ContentBlock'
import MapMessages from '../components/MapMessages'
import TotalAmount from '../components/wallet/TotalAmount'
import UniversalCompactView from '../components/wallet/UniversalCompactView'
import { requestWithdraw } from '../redux/actions/wallet'


const Styles = styled.div`
   *{
      border-color: #1461af60 !important;
   }
`
const PageWithdraw = ({ rates, wallets, requestWithdraw }) => {

   const { t } = useTranslation()

   const initialState = {
      withdraw_from: 'BTC',
      withdraw_amount: wallets.BTC?.value?.available ?? 0,
      withdraw_to: '',
      withdraw_type: 'account', // wallet, bank
      wallet: '',
      bank_acname: '',
      bank_name: '',
      bank_brname: '',
      bank_braddress: '',
      bank_acnumber: '',
      bank_swift: '',
      bank_iban: '',
   }

   const [form, setform] = useState(initialState)

   useEffect(() => {
      if (wallets[form.withdraw_from]?.value ?? false) {
         if (form.withdraw_amount > wallets[form.withdraw_from].value.available) {
            setform({ ...form, withdraw_amount: wallets[form.withdraw_from].value.available })
         }
      }
   }, [form, form.withdraw_amount, form.withdraw_from, wallets])

   const setMaxValue = () => {
      //console.log(wallets[form.withdraw_from])
      setform({ ...form, withdraw_amount: wallets[form.withdraw_from].value.available })
   }

   const mapOptions = () => {
      const wArr = []

      for (const curr in rates.data) {
         if (Object.hasOwnProperty.call(wallets, curr)) {
            const element = (<option key={`${curr}`} value={curr}>{curr}</option>)

            wArr.push(element)
         }
      }

      return wArr
   }

   let history = useHistory()
   let location = useLocation()

   const [path, setPath] = useState(location.pathname)

   useEffect(() => {
      history.replace({ pathname: path })
   }, [history, path])

   const assetsWallets = () => {

      let assetsObj = Object.keys(wallets)
         .filter(curr => wallets[curr].value?.available > 0)
         .sort((b, a) => {
            return wallets[a].value.available * rates.data[a]?.USD?.price - wallets[b].value.available * rates.data[b]?.USD?.price
         })
         .reduce((acc, key) => {
            acc[key] = wallets[key]
            return acc
         }, {})

      //console.log(assetsObj)
      return assetsObj
   }

   const mapWallets = () => {
      const wArr = []

      const walletsObj = assetsWallets()

      for (const curr in walletsObj) {
         if (Object.hasOwnProperty.call(rates.data, curr)) {

            if (wallets[curr].value.available === 0)
               continue

            const logo = <img alt={curr} src={`icons/${curr.toLowerCase()}.svg`} />
            const element = (
               <ContentBlock key={curr} className="border border-dark m-2">
                  <UniversalCompactView
                     rates={rates.data[curr]}
                     wallet={wallets[curr]}
                     code={curr}
                     title={rates.names[curr]}
                     logo={logo}
                     onClick={() => setPath(`/crypto/${curr.toUpperCase()}`)}
                  />
               </ContentBlock>
            )

            wArr.push(element)
         }
      }

      return wArr
   }

   const changeInputHandler = e => {
      const { type, checked, name, value } = e.target;
      setform(prev => ({
         ...prev, ...{
            [name]: type === 'checkbox' ? checked : value
         }
      }))
   }

   const submitHandler = e => {
      e.preventDefault()
      requestWithdraw(form)
   }

   return (
      <Container className="px-1 text-custom-blue">
         <ContentBlock>
            <TotalAmount />
         </ContentBlock>

         <ContentBlock>
            <Link to={`${process.env.PUBLIC_URL}/withdrawterms`} className="text-custom-blue text-decoration-underline font-weight-bold">
               <span><u>{t('page.withdrawterms.title')}</u></span>
            </Link>
         </ContentBlock>

         <Styles>
            <ContentBlock className="border border-1 m-2">
               <Form onSubmit={submitHandler}>
                  <Form.Row>
                     <Form.Group as={Col} xs={4}>
                        <Form.Label>{t('form.withdraw.label.from')} :</Form.Label>
                        <Form.Control
                           as="select"
                           size="lg"
                           name="withdraw_from"
                           value={form.withdraw_from}
                           onChange={changeInputHandler}
                        >
                           {mapOptions()}
                        </Form.Control>
                     </Form.Group>
                     <Form.Group as={Col} xs={8}>
                        <Form.Label>{t('form.withdraw.label.amount')}</Form.Label>
                        <InputGroup>
                           <Form.Control
                              size="lg"
                              name="withdraw_amount"
                              type="number"
                              step="any"
                              inputMode="decimal"
                              value={form.withdraw_amount}
                              onChange={changeInputHandler}
                           />
                           <InputGroup.Append>
                              <Button className="background-grad-hor" variant="secondary" onClick={setMaxValue}>
                                 {t('form.withdraw.button.max')}
                              </Button>
                           </InputGroup.Append>
                        </InputGroup>
                     </Form.Group>
                  </Form.Row>

                  <Form.Row>
                     <Form.Group as={Col} xs={12}>
                        <Form.Label>{t('form.withdraw.label.to')}</Form.Label>
                        <Form.Control
                           size="lg"
                           type="email"
                           name="withdraw_to"
                           placeholder={t('form.withdraw.placeholder.to')}
                           value={form.withdraw_to}
                           onChange={changeInputHandler}
                        />
                     </Form.Group>

                  </Form.Row>

                  <Row className='mb-3'>
                     <Col>
                        <Form.Check
                           type='radio'
                           name='withdraw_type'
                           value='account'
                           label={t('form.withdraw.label.type1')}
                           checked={(form.withdraw_type === 'account')}
                           onChange={changeInputHandler}
                        />
                        <Form.Check
                           type='radio'
                           name='withdraw_type'
                           value='wallet'
                           label={t('form.withdraw.label.type2')}
                           checked={(form.withdraw_type === 'wallet')}
                           onChange={changeInputHandler}
                        />
                        <Form.Check
                           type='radio'
                           name='withdraw_type'
                           value='bank'
                           label={t('form.withdraw.label.type3')}
                           checked={(form.withdraw_type === 'bank')}
                           onChange={changeInputHandler}
                        />
                     </Col>
                  </Row>

                  {(form.withdraw_type === 'wallet') &&
                     <Form.Row>
                        <Form.Group as={Col} xs={12}>
                           <Form.Control
                              size="lg"
                              type="text"
                              name="wallet"
                              placeholder={t('form.withdraw.placeholder.wallet')}
                              value={form.withdraw_wallet}
                              onChange={changeInputHandler}
                           />
                        </Form.Group>

                     </Form.Row>
                  }

                  {(form.withdraw_type === 'bank') &&
                     <Form.Row>
                        <Form.Group as={Col} xs={12}>
                           <Form.Control
                              size="lg"
                              type="text"
                              name="bank_acname"
                              placeholder={t('form.withdraw.placeholder.bank_acname')}
                              value={form.bank_acname}
                              onChange={changeInputHandler}
                              className='mb-2'
                           />
                           <Form.Control
                              size="lg"
                              type="text"
                              name="bank_name"
                              placeholder={t('form.withdraw.placeholder.bank_name')}
                              value={form.bank_name}
                              onChange={changeInputHandler}
                              className='mb-2'
                           />
                           <Form.Control
                              size="lg"
                              type="text"
                              name="bank_brname"
                              placeholder={t('form.withdraw.placeholder.bank_brname')}
                              value={form.bank_brname}
                              onChange={changeInputHandler}
                              className='mb-2'
                           />
                           <Form.Control
                              size="lg"
                              type="text"
                              name="bank_braddress"
                              placeholder={t('form.withdraw.placeholder.bank_braddress')}
                              value={form.bank_braddress}
                              onChange={changeInputHandler}
                              className='mb-2'
                           />
                           <Form.Control
                              size="lg"
                              type="text"
                              name="bank_acnumber"
                              placeholder={t('form.withdraw.placeholder.bank_acnumber')}
                              value={form.bank_acnumber}
                              onChange={changeInputHandler}
                              className='mb-2'
                           />
                           <Form.Control
                              size="lg"
                              type="text"
                              name="bank_swift"
                              placeholder={t('form.withdraw.placeholder.bank_swift')}
                              value={form.bank_swift}
                              onChange={changeInputHandler}
                              className='mb-2'
                           />
                           <Form.Control
                              size="lg"
                              type="text"
                              name="bank_iban"
                              placeholder={t('form.withdraw.placeholder.bank_iban')}
                              value={form.bank_iban}
                              onChange={changeInputHandler}
                              className='mb-2'
                           />
                        </Form.Group>

                     </Form.Row>
                  }

                  <Row>
                     <Col>
                        <Button type="submit" className="w-100 background-grad-hor-reverce" variant="dark" size="lg">
                           {t('form.withdraw.button.submit')}
                        </Button>
                     </Col>
                  </Row>
                  {wallets.withdraw.message &&
                     <Row className="mt-3">
                        <Col>
                           <Alert
                              className="font-weight-bold text-center"
                              size="xl"
                              variant={wallets.withdraw.success ? 'success' : 'danger'}
                           >
                              {MapMessages(wallets.withdraw.message)}
                           </Alert>
                        </Col>
                     </Row>
                  }
               </Form>
            </ContentBlock>
         </Styles>

         <h4 className="text-center text-custom-blue my-3">{t('page.convert.title1')}</h4>

         {mapWallets()}
      </Container>
   )
}

const mapStateToProps = state => {
   return {
      rates: state.rates,
      wallets: state.wallets
   }
}

const mapDispatchToProps = {
   requestWithdraw
}

export default connect(mapStateToProps, mapDispatchToProps)(PageWithdraw)

