import { call, put, select, takeEvery } from "redux-saga/effects";
import { Api } from "../../Api";
import { appHideLoader, appShowLoader } from "../actions/app";
import { convertionFaild, convertionStart, convertionSuccess, requestWallets, walletsLoadFaild, walletsLoadStart, walletsLoadSuccess, withdrawFaild, withdrawStart, withdrawSuccess } from "../actions/wallet";
import { CONVERT_REQUESTED, WALLETS_REQUESTED, WITHDRAW_REQUESTED } from "../types/wallet";

// worker Saga: will be fired on WALLETS_REQUESTED actions
function* fetchWallets(action) {
   yield put(walletsLoadStart());
   //yield put(appShowLoader())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.fetchWallets, token);
      //console.log(result)
      yield put(walletsLoadSuccess(result.data));
      //console.log(result.errors || [result.message || 'Wallets loaded successfull']);
   } catch (e) {
      yield put(walletsLoadFaild());
      //console.log(e.errors || [e.message || 'Something went wrong']);
   }

   //yield put(appHideLoader())
}
// worker Saga: will be fired on CONVERT_REQUESTED actions
function* fetchConvertion(action) {
   yield put(convertionStart())
   yield put(appShowLoader())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.fetchConvertion, token, action.payload);
      //console.log(result)
      yield put(convertionSuccess(result.errors || [result.message]));
      yield put(requestWallets())
      //console.log(result.errors || [result.message || 'Wallets loaded successfull']);
   } catch (e) {
      yield put(convertionFaild(e.errors || [e.message || 'Something went wrong']));
      //console.log(e.errors || [e.message || 'Something went wrong']);
   }

   yield put(appHideLoader())
}

// worker Saga: will be fired on WITHDRAW_REQUESTED actions
function* fetchWithdraw(action) {
   yield put(withdrawStart())
   yield put(appShowLoader())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.fetchWithdraw, token, action.payload);
      //console.log(result)
      yield put(withdrawSuccess(result.errors || [result.message]));
      yield put(requestWallets())
      //console.log(result.errors || [result.message || 'Wallets loaded successfull']);
   } catch (e) {
      yield put(withdrawFaild(e.errors || [e.message || 'Something went wrong']));
      //console.log(e.errors || [e.message || 'Something went wrong']);
   }

   yield put(appHideLoader())
}

/*
  Starts fetchWallets on each dispatched `WALLETS_REQUESTED` action.
  Starts fetchConvertion on each dispatched `CONVERT_REQUESTED` action.
  Starts fetchWithdraw on each dispatched `WITHDRAW_REQUESTED` action.
*/
export function* walletSagas() {
   yield takeEvery(WALLETS_REQUESTED, fetchWallets);
   yield takeEvery(CONVERT_REQUESTED, fetchConvertion);
   yield takeEvery(WITHDRAW_REQUESTED, fetchWithdraw);
}
