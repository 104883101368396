export const helperHttp = (token) => {

   const request = async (url, body, headers = {}, method = 'POST') => {

      headers['Content-Type'] = 'application/json'
      headers['Accept'] = 'application/json'

      token && (headers['Authorization'] = 'Bearer ' + token)

      try {
         const response = await fetch(url, { method, body, headers })
         const data = await response.json()

         if (!response.ok || !data.success) {
            let responseError = new Error(data.message || 'Something went wrong')
            responseError.errors = data.errors || [data.message] || []
            responseError.authorized = data.authorized
            throw responseError
         }

         return data

      } catch (e) {
         throw e
      }
   }

   return request
}