import React from 'react'
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'react-bootstrap'

const RatesTable = ({ rates }) => {
   return (
      <>
         <Row className="mb-2">
            <Col>Price (USD)</Col>
            <Col className="ml-auto text-right text-nowrap">
               {rates !== undefined ? rates.USD.price.toFixed(4) : '------'}
            </Col>
         </Row>

         <Row className="mb-2">
            <Col>1 hour</Col>
            <Col className="d-flex ml-auto text-nowrap">
               {rates !== undefined
                  ? rates.USD.percent_change_1h >= 0
                     ? <span className="text-success mr-auto"><FontAwesomeIcon icon={faSortUp} /></span>
                     : <span className="text-danger mr-auto"><FontAwesomeIcon icon={faSortDown} /></span>
                  : '------'
               }
               {rates !== undefined && rates.USD.percent_change_1h.toFixed(4)}
               <span className="pl-1 text-info">(%)</span>
            </Col>
         </Row>

         <Row className="mb-2">
            <Col>24 hours</Col>
            <Col className="d-flex ml-auto text-nowrap">
               {rates !== undefined
                  ? rates.USD.percent_change_24h >= 0
                     ? <span className="text-success mr-auto"><FontAwesomeIcon icon={faSortUp} /></span>
                     : <span className="text-danger mr-auto"><FontAwesomeIcon icon={faSortDown} /></span>
                  : '------'
               }
               {rates !== undefined && rates.USD.percent_change_24h.toFixed(4)}
               <span className="pl-1 text-info">(%)</span>
            </Col>
         </Row>

         <Row className="mb-2">
            <Col>7 days</Col>
            <Col className="d-flex ml-auto text-nowrap">
               {rates !== undefined
                  ? rates.USD.percent_change_7d >= 0
                     ? <span className="text-success mr-auto"><FontAwesomeIcon icon={faSortUp} /></span>
                     : <span className="text-danger mr-auto"><FontAwesomeIcon icon={faSortDown} /></span>
                  : '------'
               }
               {rates !== undefined && rates.USD.percent_change_7d.toFixed(4)}
               <span className="pl-1 text-info">(%)</span>
            </Col>
         </Row>

         <Row className="mb-2">
            <Col>30 days</Col>
            <Col className="d-flex ml-auto text-nowrap">
               {rates !== undefined
                  ? rates.USD.percent_change_30d >= 0
                     ? <span className="text-success mr-auto"><FontAwesomeIcon icon={faSortUp} /></span>
                     : <span className="text-danger mr-auto"><FontAwesomeIcon icon={faSortDown} /></span>
                  : '------'
               }
               {rates !== undefined && rates.USD.percent_change_30d.toFixed(4)}
               <span className="pl-1 text-info">(%)</span>
            </Col>
         </Row>

         <Row className="mb-2">
            <Col>60 days</Col>
            <Col className="d-flex ml-auto text-nowrap">
               {rates !== undefined
                  ? rates.USD.percent_change_60d >= 0
                     ? <span className="text-success mr-auto"><FontAwesomeIcon icon={faSortUp} /></span>
                     : <span className="text-danger mr-auto"><FontAwesomeIcon icon={faSortDown} /></span>
                  : '------'
               }
               {rates !== undefined && rates.USD.percent_change_60d.toFixed(4)}
               <span className="pl-1 text-info">(%)</span>
            </Col>
         </Row>

         <Row className="mb-2">
            <Col>90 days</Col>
            <Col className="d-flex ml-auto text-nowrap">
               {rates !== undefined
                  ? rates.USD.percent_change_90d >= 0
                     ? <span className="text-success mr-auto"><FontAwesomeIcon icon={faSortUp} /></span>
                     : <span className="text-danger mr-auto"><FontAwesomeIcon icon={faSortDown} /></span>
                  : '------'
               }
               {rates !== undefined && rates.USD.percent_change_90d.toFixed(4)}
               <span className="pl-1 text-info">(%)</span>
            </Col>
         </Row>
      </>
   )
}

export default RatesTable
