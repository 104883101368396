import { call, put, select, takeEvery } from "redux-saga/effects";
import { Api } from "../../Api";
import { ratesLoadFaild, ratesLoadStart, ratesLoadSuccess } from "../actions/rates";
import { RATES_REQUESTED } from "../types/rates";

// worker Saga: will be fired on RATES_REQUESTED actions
function* fetchRates(action) {
   yield put(ratesLoadStart());
   //yield put(appShowLoader())

   try {
      const token = yield select(state => state.auth.token)
      const result = yield call(Api.fetchRates, token);
      //console.log(result)
      yield put(ratesLoadSuccess(result.data));
      //console.log(result.errors || [result.message || 'Login successfull']);
   } catch (e) {
      yield put(ratesLoadFaild());
      //console.log(e.errors || [e.message || 'Something went wrong']);
   }

   //yield put(appHideLoader())
}

/*
  Starts fetchRates on each dispatched `RATES_REQUESTED` action.
*/
export function* ratesSagas() {
   yield takeEvery(RATES_REQUESTED, fetchRates);
}
