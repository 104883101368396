import { PAY_TP_REQUESTED, PAY_TP_SUCCESS, PAY_TP_FAILED, PAY_LINK_CLEAR, PAY_TP_START } from "../types/pay"

export const requestTpPay = payload => {
   return {
      type: PAY_TP_REQUESTED,
      payload
   }
}

export const startTpPay = () => {
   return {
      type: PAY_TP_START
   }
}

export const payTpSuccess = payload => {
   return {
      type: PAY_TP_SUCCESS,
      payload
   }
}

export const payTpFaild = payload => {
   return {
      type: PAY_TP_FAILED,
      payload
   }
}

export const payLinkClear = () => {
   return {
      type: PAY_LINK_CLEAR
   }
}
