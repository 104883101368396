import {
   WALLETS_CLEAR_ALL,
   WALLETS_LOAD_FAILED,
   WALLETS_LOAD_START,
   WALLETS_LOAD_SUCCESS,
   WALLETS_REQUESTED,
   CONVERT_CLEAR_MSG,
   CONVERT_FAILED,
   CONVERT_REQUESTED,
   CONVERT_START,
   CONVERT_SUCCESS,
   WITHDRAW_CLEAR_MSG,
   WITHDRAW_FAILED,
   WITHDRAW_REQUESTED,
   WITHDRAW_START,
   WITHDRAW_SUCCESS
} from "../types/wallet"

export const requestWallets = () => {
   return {
      type: WALLETS_REQUESTED
   }
}

export const walletsLoadStart = () => {
   return {
      type: WALLETS_LOAD_START
   }
}

export const walletsLoadSuccess = payload => {
   return {
      type: WALLETS_LOAD_SUCCESS,
      payload
   }
}

export const walletsLoadFaild = () => {
   return {
      type: WALLETS_LOAD_FAILED
   }
}

export const walletsClearAll = () => {
   return {
      type: WALLETS_CLEAR_ALL
   }
}

/* CONVERTION */
export const requestConvertion = payload => {
   return {
      type: CONVERT_REQUESTED,
      payload
   }
}

export const convertionStart = () => {
   return {
      type: CONVERT_START
   }
}

export const convertionSuccess = payload => {
   return {
      type: CONVERT_SUCCESS,
      payload
   }
}

export const convertionFaild = payload => {
   return {
      type: CONVERT_FAILED,
      payload
   }
}

export const convertionClearMSG = () => {
   return {
      type: CONVERT_CLEAR_MSG
   }
}

/* WITHDRAW */
export const requestWithdraw = payload => {
   return {
      type: WITHDRAW_REQUESTED,
      payload
   }
}

export const withdrawStart = () => {
   return {
      type: WITHDRAW_START
   }
}

export const withdrawSuccess = payload => {
   return {
      type: WITHDRAW_SUCCESS,
      payload
   }
}

export const withdrawFaild = payload => {
   return {
      type: WITHDRAW_FAILED,
      payload
   }
}

export const withdrawClearMSG = () => {
   return {
      type: WITHDRAW_CLEAR_MSG
   }
}
