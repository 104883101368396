import React from 'react'
import { Col, Row } from 'react-bootstrap'
import RatesTable from '../RatesTable'

const UniversalFullView = ({ rates, wallet, title, code, logo, ...rest }) => {

   const { available /*, unconfirmed, locked */ } = wallet?.value ?? 0;

   const usdAmount = rates ? available * rates.USD.price : 0

   return (
      <>
         <Row className="mb-4" >
            <Col className="my-auto" xs={2} md={1}>{logo}</Col>
            <Col>
               <Row className="h-100">
                  <Col className="d-flex align-items-center font-weight-bold h-100">{title}<br />{code}</Col>
               </Row>
            </Col>
            <Col className="ml-auto text-right font-weight-bold">
               <Row>
                  <Col>{(available ?? 0).toFixed(wallet.settings.is_token ? 0 : 8)}</Col>
               </Row>
               <Row>
                  <Col>$ {(usdAmount ?? 0).toFixed(2)}</Col>
               </Row>
            </Col>
         </Row>

         <RatesTable rates={rates} />
      </>
   )
}

export default UniversalFullView
