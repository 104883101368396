import { APP_HIDE_LOADER, APP_SHOW_LOADER } from "../types/app"

export function appShowLoader() {
   return {
      type: APP_SHOW_LOADER
   }
}

export function appHideLoader() {
   return {
      type: APP_HIDE_LOADER
   }
}
