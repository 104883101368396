import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom'
import { Loader } from './components/Loader';
import NavBottom from './components/NavBottom';
import NavTop from './components/NavTop';
import { requestLoginCheck } from './redux/actions/auth';
import { requestInfo } from './redux/actions/crypto';
import { requestRates } from './redux/actions/rates';
import { requestWallets } from './redux/actions/wallet';
import { useRoutes } from './router/useRoutes';

const App = ({ auth, loading, requestLoginCheck, requestRates, requestInfo, requestWallets }) => {


   useEffect(() => {
      auth.token && requestLoginCheck()
   }, [auth.token, requestLoginCheck])

   useEffect(() => {

      let ratesUpdateInterval = false;
      if (auth.authorized) {
         requestRates()
         requestInfo()
         ratesUpdateInterval = setInterval(requestRates, 60 * 1000) // 60 sec
      }

      return () => {
         clearInterval(ratesUpdateInterval)
      }
   }, [auth.authorized, requestInfo, requestRates])

   useEffect(() => {

      let walletsUpdateInterval = false;
      if (auth.authorized) {
         walletsUpdateInterval = setInterval(requestWallets, 60 * 1000) // 60 sec
      }

      return () => {
         clearInterval(walletsUpdateInterval)
      }
   }, [auth.authorized, requestWallets])

   return (
      <>
         {loading && <Loader />}
         <Router basename={'/'}>
            {auth.authorized && <NavTop />}
            {auth.authorized && <NavBottom />}
            {useRoutes()}
         </Router>
      </>
   );
}

const mapStateToProps = (state) => ({
   auth: state.auth,
   loading: state.app.loading
})

const mapDispatchToProps = {
   requestLoginCheck, requestRates, requestInfo, requestWallets
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
