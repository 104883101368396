import { APP_HIDE_LOADER, APP_SHOW_LOADER } from "../types/app"

const initialState = {
   loading: false,
   language: 'en'
}

export const appReducer = (state = initialState, action) => {
   switch (action.type) {
      case APP_SHOW_LOADER:
         return { ...state, loading: true }

      case APP_HIDE_LOADER:
         return { ...state, loading: false }

      default: return state
   }
}


