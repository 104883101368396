import {
   WALLETS_LOAD_FAILED,
   WALLETS_LOAD_START,
   WALLETS_LOAD_SUCCESS,
   WALLETS_CLEAR_ALL,
   CONVERT_START,
   CONVERT_SUCCESS,
   CONVERT_FAILED,
   CONVERT_CLEAR_MSG,
   WITHDRAW_START,
   WITHDRAW_SUCCESS,
   WITHDRAW_FAILED,
   WITHDRAW_CLEAR_MSG
} from "../types/wallet"

const initialState = {
   loading: false,
   convertion: {
      message: null,
      success: false
   },
   withdraw: {
      message: null,
      success: false
   }
}

export const walletsReducer = (state = initialState, action) => {
   switch (action.type) {
      /* WALLETS LOAD */
      case WALLETS_LOAD_START:
         return { ...state, loading: true }

      case WALLETS_LOAD_SUCCESS:
         return { ...state, loading: false, ...reduceWallets(action.payload) }

      case WALLETS_LOAD_FAILED:
         return { ...state, loading: false }

      case WALLETS_CLEAR_ALL:
         return { ...initialState }

      /* CONVERTION */
      case CONVERT_START:
         return { ...state, convertion: { ...state.convertion, message: null, success: false } }

      case CONVERT_SUCCESS:
         return { ...state, convertion: { ...state.convertion, message: action.payload, success: true } }

      case CONVERT_FAILED:
         return { ...state, convertion: { ...state.convertion, message: action.payload, success: false } }

      case CONVERT_CLEAR_MSG:
         return { ...state, convertion: { ...state.convertion, message: null } }

      /* WITHDRAW */
      case WITHDRAW_START:
         return { ...state, withdraw: { ...state.withdraw, message: null, success: false } }

      case WITHDRAW_SUCCESS:
         return { ...state, withdraw: { ...state.withdraw, message: action.payload, success: true } }

      case WITHDRAW_FAILED:
         return { ...state, withdraw: { ...state.withdraw, message: action.payload, success: false } }

      case WITHDRAW_CLEAR_MSG:
         return { ...state, withdraw: { ...state.withdraw, message: null } }

      default: return state
   }
}

const reduceWallets = (wallets) => {
   for (const key in wallets) {
      if (Object.hasOwnProperty.call(wallets, key)) {
         wallets[key].value.available = parseFloat(wallets[key].value.available)
         wallets[key].value.unconfirmed = parseFloat(wallets[key].value.unconfirmed)
         wallets[key].value.locked = parseFloat(wallets[key].value.locked)

         if (wallets[key].tokens) {
            wallets[key].tokens = reduceTokens(wallets[key].tokens)
         }
         if (wallets[key].settings) {
            wallets[key].settings = reduceSettings(wallets[key].settings)
         }
      }
   }

   return wallets
}

const reduceTokens = tokens => {
   for (const key in tokens) {
      if (Object.hasOwnProperty.call(tokens, key)) {
         const token = tokens[key]
         tokens[key].available = parseFloat(token.available)
         tokens[key].unconfirmed = parseFloat(token.unconfirmed)
         tokens[key].locked = parseFloat(token.locked)
         tokens[key].rate = parseFloat(token.rate)
      }
   }

   return tokens
}

const reduceSettings = settings => {
   for (const key in settings) {
      if (Object.hasOwnProperty.call(settings, key)) {
         const value = settings[key]
         switch (key) {
            case 'is_token':
            case 'is_converted':
            case 'is_buyable':
               settings[key] = value === '1' ? true : false
               break

            default: break

         }
      }
   }

   return settings
}