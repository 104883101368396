import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router';
import { routerConfig } from './routerConfig';
import styled from 'styled-components'

const Styles = styled.div`
   .page-wrapper{
      height: 100vh;
      padding: 76px 0;
      position: relative;
   }
   .page-container{
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
   }
`

export const useRoutes = () => {

   const authorized = useSelector(state => state.auth.authorized);

   return (
      <>
         {authorized && <Styles>
            <div className="page-wrapper">
               <div className="page-container">
                  <Switch>
                     {routerConfig.public.map((route, i) => (<Route key={i} {...route} />))}
                     {authorized && routerConfig.private.map((route, i) => (<Route key={i} {...route} />))}

                     {!authorized && <Redirect to={`${process.env.PUBLIC_URL}/welcome`}></Redirect>}
                  </Switch>
               </div>
            </div>
         </Styles>
         }
         {!authorized &&
            <Switch>
               {routerConfig.public.map((route, i) => (<Route key={i} {...route} />))}
               {authorized && routerConfig.private.map((route, i) => (<Route key={i} {...route} />))}

               {!authorized && <Redirect to={`${process.env.PUBLIC_URL}/welcome`}></Redirect>}
            </Switch>
         }
      </>
   )
}

