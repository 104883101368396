import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import { rootReducer } from './rootReducer';
import { authSagas } from './sagas/authSagas'
import { cryptoSagas } from './sagas/cryptoSagas';
import { paySagas } from './sagas/paySagas';
import { ratesSagas } from './sagas/ratesSagas';
import { recoverSagas } from './sagas/recoverSagas';
import { walletSagas } from './sagas/walletSagas';
import { savingsSagas } from './sagas/savingsSagas';

const composeEnhancers = composeWithDevTools({
   // Specify name here, actionsBlacklist, actionsCreators and other options if needed
})

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const initialState = {}

const store = createStore(
   rootReducer,
   initialState,
   composeEnhancers(
      applyMiddleware(thunk, sagaMiddleware
         // other store enhancers if any
      )
   )
)

// then run the saga
sagaMiddleware.run(authSagas)
sagaMiddleware.run(recoverSagas)
sagaMiddleware.run(ratesSagas)
sagaMiddleware.run(cryptoSagas)
sagaMiddleware.run(walletSagas)
sagaMiddleware.run(paySagas)
sagaMiddleware.run(savingsSagas)

export default store
