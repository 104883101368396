import {
   LOGIN_START,
   LOGIN_SUCCESS,
   LOGIN_FAILED,
   LOGOUT,
   LOGIN_CHECK_START,
   LOGIN_CHECK_SUCCESS,
   LOGIN_CHECK_FAILED,
   AUTH_CLEAR_MESSAGE,
   AUTH_SET_MESSAGE,
   REGISTER_START,
   REGISTER_SUCCESS,
   REGISTER_FAILED
} from "../types/auth"

const initialState = {
   token: localStorage.getItem('token'),
   authorized: localStorage.getItem('token') ? true : false,
   message: null,
   checkInProgress: false,
   logInProgress: false,
   user: null
}

const loginStartState = {
   ...initialState,
   token: null,
   authorized: false,
   logInProgress: true,
   user: null
}

export const authReducer = (state = initialState, action) => {
   switch (action.type) {

      case AUTH_SET_MESSAGE:
         return { ...state, message: action.payload }

      case AUTH_CLEAR_MESSAGE:
         return { ...state, message: null }

      case REGISTER_START:
      case LOGIN_START:
         return { ...loginStartState }

      case REGISTER_SUCCESS:
      case LOGIN_SUCCESS:
         localStorage.setItem('token', action.payload.token)
         return { ...state, authorized: true, token: action.payload.token, user: action.payload.user }

      case LOGIN_CHECK_START:
         return { ...state, checkInProgress: true }

      case LOGIN_CHECK_SUCCESS:
         localStorage.setItem('token', action.payload.token)
         return { ...state, authorized: true, checkInProgress: false, token: action.payload.token, user: action.payload.user }

      case LOGIN_FAILED:
      case REGISTER_FAILED:
      case LOGIN_CHECK_FAILED:
      case LOGOUT:
         localStorage.removeItem('token')
         return { ...initialState, token: null, authorized: false }

      default: return state
   }
}