import React, { useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import ContentBlock from '../components/ContentBlock'
import TotalAmount from '../components/wallet/TotalAmount'
import UniversalCompactView from '../components/wallet/UniversalCompactView'

const PageHome = ({ wallets, rates }) => {

   const { t } = useTranslation()

   let history = useHistory()
   let location = useLocation()

   const [path, setPath] = useState(location.pathname)

   const [assets, setAssets] = useState(true)

   useEffect(() => {
      history.replace({ pathname: path })
   }, [history, path])

   const assetsWallets = () => {

      let assetsObj = Object.keys(wallets)
         .filter(curr => (wallets[curr].value?.available > 0 /* || wallets[curr].tokens */))
         .sort((b, a) => {
            return wallets[a].value.available * rates.data[a]?.USD?.price - wallets[b].value.available * rates.data[b]?.USD?.price
         })
         .reduce((acc, key) => {
            acc[key] = wallets[key]
            return acc
         }, {})

      //console.log(assetsObj)
      return assetsObj
   }

   const nonAssetsWallets = () => {

      let assetsObj = Object.keys(wallets)
         .filter(curr => wallets[curr].value?.available === 0)
         .sort()
         .reduce((acc, key) => {
            acc[key] = wallets[key]
            return acc
         }, {})

      //console.log(assetsObj)
      return assetsObj
   }

   const mapWallets = () => {
      const wArr = []

      const walletsObj = assets ? assetsWallets() : Object.assign({}, assetsWallets(), nonAssetsWallets())

      for (const curr in walletsObj) {
         if (Object.hasOwnProperty.call(rates.data, curr)) {

            const logo = <img alt={curr} src={`icons/${curr.toLowerCase()}.svg`} />
            const element = (
               <ContentBlock key={curr} className="border border-dark m-2">
                  <UniversalCompactView
                     rates={rates.data[curr]}
                     wallet={walletsObj[curr]}
                     code={curr}
                     title={rates.names[curr]}
                     logo={logo}
                     onClick={() => setPath(`/crypto/${curr.toUpperCase()}`)}
                  />
               </ContentBlock>
            )

            wArr.push(element)
         }
      }

      return wArr
   }

   return (
      <>
         <Container className="px-1">
            <ContentBlock>
               <TotalAmount />
            </ContentBlock>

            <div className="mb-3">
               <div className="d-flex justify-content-around">
                  <Button
                     variant="outline-primary"
                     onClick={() => setAssets(true)}
                     className={(assets ? 'background-grad-hor text-white' : '') + ' w-100 mx-2'}
                  >{t('page.home.button.myassets')}</Button>
                  <Button
                     variant="outline-primary"
                     onClick={() => setAssets(false)}
                     className={(assets ? '' : 'background-grad-hor-reverce text-white') + ' w-100 mx-2'}
                  >{t('page.home.button.allassets')}</Button>
               </div>
            </div>

            {mapWallets()}
         </Container>
      </>
   )
}

const mapStateToProps = state => {
   return {
      wallets: state.wallets,
      rates: state.rates
   }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PageHome)
