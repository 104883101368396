import React, { useEffect, useState } from 'react'
import { Form, Button, Col, FormControl, Row } from 'react-bootstrap'
import { Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import MapMessages from '../MapMessages'

const LoginForm = ({ auth, actionSubmit, clearMessage }) => {

   const { t } = useTranslation()

   const initialState = {
      username: '',
      password: '',
      remember: false
   }
   const [form, setform] = useState(initialState)

   useEffect(() => {
      clearMessage()
   }, [clearMessage])

   const changeInputHandler = e => {
      const { type, checked, name, value } = e.target;
      setform(prev => ({
         ...prev, ...{
            [name]: type === 'checkbox' ? checked : value
         }
      }))
   }

   const submitHandler = e => {
      e.preventDefault()
      clearMessage()
      actionSubmit(form)
   }

   return (
      <>
         <Row>
            <Col>
               {auth.message
                  ? <Alert variant={auth.token ? 'success' : 'danger'}>{MapMessages(auth.message)}</Alert>
                  : <div>&nbsp;</div>
               }
            </Col>
         </Row>

         {(!auth.authorized && !auth.checkInProgress) && (
            <Form onSubmit={submitHandler}>
               <div className="p-4 mb-4 rounded-lg bg-white w-100 container-login">
                  <Form.Row>
                     <Col>
                        <Form.Group className="mb-1">
                           <FormControl
                              placeholder={t('form.login.label.username')}
                              aria-label="Username"
                              aria-describedby="login-form-addon-username"
                              name="username"
                              type="text"
                              value={form.username}
                              onChange={changeInputHandler}
                           />
                        </Form.Group>
                     </Col>
                  </Form.Row>

                  <Form.Row>
                     <Col>
                        <Form.Group className="mb-1">
                           <FormControl
                              placeholder={t('form.login.label.password')}
                              aria-label="Password"
                              aria-describedby="login-form-addon-password"
                              name="password"
                              type="password"
                              value={form.password}
                              onChange={changeInputHandler}
                           />
                        </Form.Group>
                     </Col>
                  </Form.Row>

                  <Link className="pl-2 mt-3 d-block" to={`${process.env.PUBLIC_URL}/passrecover`}  ><ins>{t('form.login.link.passforgot')}</ins></Link>
               </div>

               {/* <Form.Row className="my-3">
                  <Col>
                     <Form.Group controlId="formLoginCheckbox">
                        <Form.Check
                           size="xl"
                           type="checkbox"
                           label={t('form.login.label.remember')}
                           checked={form.remember}
                           onChange={changeInputHandler}
                        />
                     </Form.Group>
                  </Col>
               </Form.Row> */}

               <Form.Row>
                  <Col className="text-center">
                     <Button
                        variant="light"
                        size="lg"
                        className="w-75 text-custom-blue font-weight-bold font-size-large button-rounded-1"
                        type="submit">
                        {t('form.login.button.submit')}
                     </Button>
                  </Col>
               </Form.Row>
            </Form>
         )}
      </>
   )
}

export default LoginForm
