import React, { useEffect, useState } from 'react'
import { Form, Button, Col, FormControl, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import countries from '../../data/jsonCountry.json'
//import MapMessages from './MapMessages'
//import { Alert } from 'react-bootstrap'

const RegisterForm = ({ auth, actionSubmit, clearMessage }) => {

   const { t } = useTranslation()

   let { ref_code } = useParams()

   const initialState = {
      username: '',
      password: '',
      firstname: '',
      lastname: '',
      country: '',
      city: '',
      address: '',
      phone: '',
      refcode: ref_code
   }
   const [form, setform] = useState(initialState)

   useEffect(() => {
      clearMessage()
   }, [clearMessage])

   const changeInputHandler = e => {
      const { type, checked, name, value } = e.target;
      setform(prev => ({
         ...prev, ...{
            [name]: type === 'checkbox' ? checked : value
         }
      }))
   }

   const submitHandler = e => {
      e.preventDefault()
      actionSubmit(form)
   }

   return (
      <>
         <Row>
            <Col>
               {/* {auth.message
                  ? <Alert variant={auth.token ? 'success' : 'danger'}>{MapMessages(auth.message)}</Alert>
                  : <div>&nbsp;</div>
               } */}
            </Col>
         </Row>

         {(!auth.authorized && !auth.checkInProgress) && (
            <Form onSubmit={submitHandler} >
               <div className="hidden">
                  <input type="hidden" name="refcode" value={form.refcode} />
               </div>
               <Form.Row>
                  <Col>
                     <Form.Group className="mb-1" >
                        <FormControl
                           placeholder={t('form.signup.label.username')}
                           aria-label="username"
                           aria-describedby="register-form-addon-username"
                           name="username"
                           type="text"
                           value={form.username}
                           onChange={changeInputHandler}
                        />
                        {auth.message && auth.message.username !== undefined
                           && <Form.Control.Feedback style={{ display: 'block' }} type="invalid">{auth.message.username}</Form.Control.Feedback>}
                     </Form.Group>
                  </Col>
               </Form.Row>

               <Form.Row>
                  <Col>
                     <Form.Group className="mb-1">
                        <FormControl
                           placeholder={t('form.signup.label.password')}
                           aria-label="Password"
                           aria-describedby="register-form-addon-password"
                           name="password"
                           type="password"
                           value={form.password}
                           onChange={changeInputHandler}
                        />
                        {/* <Form.Text className="text-muted">{t('form.signup.desc.password')}</Form.Text> */}
                        {auth.message && auth.message.password !== undefined
                           && <Form.Control.Feedback style={{ display: 'block' }} type="invalid">{auth.message.password}</Form.Control.Feedback>}
                     </Form.Group>
                  </Col>
               </Form.Row>

               <Form.Row>
                  <Col>
                     <Form.Group className="mb-1">
                        <FormControl
                           placeholder={t('form.signup.label.fname')}
                           aria-label="firstname"
                           aria-describedby="register-form-addon-firstname"
                           name="firstname"
                           type="text"
                           value={form.firstname}
                           onChange={changeInputHandler}
                        />
                     </Form.Group>
                  </Col>
               </Form.Row>

               <Form.Row>
                  <Col>
                     <Form.Group className="mb-1">
                        <FormControl
                           placeholder={t('form.signup.label.lname')}
                           aria-label="lastname"
                           aria-describedby="register-form-addon-lastname"
                           name="lastname"
                           type="text"
                           value={form.lastname}
                           onChange={changeInputHandler}
                        />
                     </Form.Group>
                  </Col>
               </Form.Row>

               <Form.Row>
                  <Col>
                     <Form.Group className="mb-1">
                        <Form.Control
                           as="select"
                           //placeholder={t('form.signup.label.country')}
                           aria-label="country"
                           //aria-describedby="register-form-addon-country"
                           name="country"
                           //type="text"
                           value={form.country}
                           onChange={(e) => {
                              changeInputHandler(e)
                              setform(prev => ({
                                 ...prev, ...{
                                    phone:
                                       e.target.value !== ''
                                          ? '+' + countries[e.target.value]?.dial_code ?? ''
                                          : e.target.value
                                 }
                              }))
                           }}
                        >
                           <option>{t('form.signup.label.country')}</option>
                           {Object.keys(countries).map(key =>
                              <option key={key} value={key}>{countries[key].name}</option>
                           )}
                        </Form.Control>
                     </Form.Group>
                  </Col>
               </Form.Row>

               <Form.Row>
                  <Col>
                     <Form.Group className="mb-1">
                        <FormControl
                           placeholder={t('form.signup.label.city')}
                           aria-label="city"
                           aria-describedby="register-form-addon-city"
                           name="city"
                           type="text"
                           value={form.city}
                           onChange={changeInputHandler}
                        />
                     </Form.Group>
                  </Col>
               </Form.Row>

               <Form.Row>
                  <Col>
                     <Form.Group className="mb-1">
                        <FormControl
                           placeholder={t('form.signup.label.address')}
                           aria-label="address"
                           aria-describedby="register-form-addon-address"
                           name="address"
                           type="text"
                           value={form.address}
                           onChange={changeInputHandler}
                        />
                     </Form.Group>
                  </Col>
               </Form.Row>

               <Form.Row>
                  <Col>
                     <Form.Group className="mb-1">
                        <FormControl
                           placeholder={t('form.signup.label.phone')}
                           aria-label="phone"
                           aria-describedby="register-form-addon-phone"
                           name="phone"
                           type="tel"
                           value={form.phone}
                           onChange={changeInputHandler}
                        />
                     </Form.Group>
                  </Col>
               </Form.Row>

               <Form.Row className="mt-3">
                  <Col className="text-center">
                     <Button className="w-75 background-grad-hor-reverce text-white" variant="default" size="lg" type="submit" >{t('form.signup.button.submit')}</Button>
                  </Col>
               </Form.Row>
            </Form>
         )
         }
      </>
   )
}

export default RegisterForm
