import React from 'react'

const MapMessages = payload => {

   let messages = payload

   if (!messages) return <span>&nbsp;</span>

   messages = typeof (messages) !== 'object'
      ? Array.isArray(messages)
         ? messages
         : [messages]
      : Object.values(messages)

   return messages.map((msg, idx) => <span key={idx} className="d-block">{msg}</span>)
}

export default MapMessages
